import React, {useState} from 'react';
import {useRouter} from 'next/router';
import Avatar from 'react-avatar';
import cx from 'classnames';

import Button, {ButtonSizes, ButtonTypes} from './ButtonNew';
import CollapserThing from './CollapserThing';
import Link from './Link';
import DropdownItem from './NavigationDropdownItem';
import KarmaPill from './KarmaPill';
import Search from './Search';
import {useAppContext, useAnalytics} from '../hooks';
import {
  CaretIcon,
  HamburgerIcon,
  CloseIcon,
  UpgradeIcon,
  GiftIcon,
} from './svg';
import Menu from '../constants/menu';
import Logo from '../../assets/images/logo.svg';
import LogoType from '../../css/images/logotype_black.png';

const MobileNavigation = ({showActive = true, upgradeLink = '/upgrade?src=nav'}) => {
  const appContext = useAppContext();
  const {tracker} = useAnalytics();
  const [expanded, setExpanded] = useState(false);
  const [expandedDropdown, setExpandedDropdown] = useState(null);
  const [canScroll, setCanScroll] = useState(true);
  const {pathname, asPath} = useRouter();
  const {currentUser, hasSubscription} = appContext;

  const shouldBeActiveFor = (paths = []) => showActive && paths.includes(pathname);
  const shouldShow = (item) => !item.onlyDesktop && !item.shouldHide?.(appContext);

  const openMenu = () => {
    document.body.classList.add('noscroll');
    setExpanded(true);
  };

  const closeMenu = () => {
    document.body.classList.remove('noscroll');
    setExpanded(false);
  };

  const closeMenuAnd = (cb) => () => {
    closeMenu();
    cb();
  };

  const toggleExpandedDropdown = ({currentTarget}) => {
    setExpandedDropdown(
      (prevExpanded) => (prevExpanded === currentTarget.name ? null : currentTarget.name),
    );
  };

  const handleClick = (item) => {
    closeMenu();
    tracker.headerClickMenu({
      platform: 'mobile',
      title: item.title,
      link: item.link,
    });
  };

  return (
    <div className="md:hidden">
      {!currentUser && (
      <Button
        size={ButtonSizes.REGULAR}
        href={`/signup?src=nav&returnPath=${asPath}`}
        onClick={() => { tracker.headerClickSignup({source: 'mobile-header'}); }}
        className="mx-4"
      >
        Sign up
      </Button>
      )}
      <button type="button" className="py-4" onClick={openMenu} aria-label="Expand menu">
        <HamburgerIcon />
      </button>

      <div className={cx('fixed top-0 left-0 right-0 bottom-0 flex flex-col bg-white z-50', {hidden: !expanded})}>

        {/* Faux header */}
        <div className="flex justify-between items-center px-4 sm:px-8 mb-1">
          <Link href="/?src=nav" onClick={closeMenu} className="flex items-center flex-shrink-0 mr-0.5">
            <img src={Logo.src} alt="Exponent Logo" className="h-6 w-6 mr-2.5" />
            <img src={LogoType.src} alt="Exponent" className="h-5 mt-1" />
          </Link>
          <button type="button" className="py-4" onClick={closeMenu} aria-label="Close menu">
            <CloseIcon />
          </button>
        </div>

        {/* Menu contents */}
        <div className={cx('block flex-grow px-2 pb-6', {
          'overflow-auto': canScroll,
          'overflow-hidden': !canScroll,
        })}
        >
          <div className="mx-1">
            <Search
              onClick={closeMenu}
              onExpand={(searchExpanded) => setCanScroll(!searchExpanded)}
            />
          </div>

          <ul className="mt-2.5">
            {Menu.primaryItems.filter(shouldShow).map((item) => ((item.items || item.gridItems) ? (
              <Dropdown
                item={item}
                isActive={shouldBeActiveFor(item.activePaths)}
                isExpanded={expandedDropdown === item.title}
                onClick={toggleExpandedDropdown}
                onClickDropdownItem={(i) => handleClick(i)}
                key={item.title}
              />
            ) : (
              <Link
                href={item.link}
                onClick={() => handleClick(item)}
                key={item.title}
                forceAnchor={item.forceAnchor}
              >
                {item.children
                  ? item.children
                  : (
                    <MenuItem isActive={shouldBeActiveFor(item.activePaths)}>
                      {item.title}
                    </MenuItem>
                  )}
              </Link>
            )))}
          </ul>
        </div>

        {/* Account section */}
        <div className="border-t pb-4">
          {currentUser ? (
            <ul>
              <li className="flex items-center justify-between pt-4 pb-3 px-5 text-lg">
                <div className="flex items-center">
                  <Link href="/account/profile?src=nav" onClick={closeMenu}>
                    <Avatar
                      name={currentUser.displayName}
                      src={currentUser.profile_img || ''}
                      color="#f0f0f0"
                      fgColor="#000000"
                      size={32}
                      className="mr-3 flex-shrink-0"
                      round
                    />
                  </Link>
                  <div className="flex-grow truncate">
                    <Link
                      href="/account/profile?src=nav"
                      onClick={closeMenu}
                      className="text-base text-gray-800 truncate"
                    >
                      {currentUser.displayName}
                    </Link>
                    <KarmaPill
                      score={currentUser.karma}
                      onClick={() => {
                        tracker.karmaClickScore({source: 'nav'});
                        closeMenu();
                      }}
                      className="inline-flex ml-1.5"
                    />
                    <Link
                      href="/account/profile?src=nav"
                      onClick={closeMenu}
                      className="block text-xs truncate"
                    >
                      {currentUser.email}
                    </Link>
                  </div>
                </div>
                {hasSubscription ? (
                  <Link
                    href="/referrals?src=nav"
                    onClick={closeMenu}
                    className="text-base font-medium text-gray-800"
                  >
                    <>
                      <GiftIcon className="h-5 w-5 mr-1 flex-shrink-0" />
                      Refer friends
                    </>
                  </Link>
                ) : (
                  <Link
                    href={upgradeLink}
                    onClick={closeMenuAnd(tracker.headerClickUpgrade)}
                    style={{color: '#10B981'}}
                    className="text-base font-medium  bg-green-100 py-1 px-2 rounded-md flex items-center"
                  >
                    <>
                      <UpgradeIcon className="h-5 w-5 my-1 mr-1 flex-shrink-0" />
                      Upgrade
                    </>
                  </Link>
                )}
              </li>
              {Menu.accountItems.filter(shouldShow).map((item) => (
                <AccountMenuItem key={item.title}>
                  <Link
                    href={item.link}
                    onClick={() => handleClick(item)}
                    forceAnchor={item.forceAnchor}
                  >
                    {item.title}
                  </Link>
                </AccountMenuItem>
              ))}
            </ul>
          ) : (
            <div className="flex flex-col">
              <Button
                size={ButtonSizes.LARGE}
                href="/signup?src=nav"
                onClick={closeMenuAnd(tracker.headerClickSignup)}
                className="mx-4 mt-4"
              >
                Sign up
              </Button>
              <Button
                size={ButtonSizes.LARGE}
                buttonType={ButtonTypes.TERTIARY}
                href="/login?src=nav"
                onClick={closeMenuAnd(tracker.headerClickLogin)}
                className="mx-4 mt-1"
              >
                Log in
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Dropdown = ({
  item, isActive, isExpanded, onClick, onClickDropdownItem,
}) => (
  <>
    <MenuItem isActive={isActive}>
      <button name={item.title} type="button" className="w-full flex justify-between items-center" onClick={onClick}>
        {item.title}
        <CaretIcon
          className={cx('transform -rotate-180 transition duration-200 mr-0.5', {'-rotate-90': isExpanded})}
          fill={isActive ? 'white' : undefined}
        />
      </button>
    </MenuItem>
    <CollapserThing visible={isExpanded}>
      <ul className="block my-1 mx-3">
        {/* Show grid items or items, but not both, for succinctness */}
        {(item.gridItems || item.items).map((dropdownItem) => (
          <DropdownItem
            item={dropdownItem}
            key={dropdownItem.title}
            onClick={() => onClickDropdownItem(dropdownItem)}
          />
        ))}
      </ul>
      {item.cta && (
        <div className="px-5 py-2">
          <Link
            className="block text-indigo text-base font-medium py-1 hover:text-indigo focus:text-indigo"
            href={item.cta.link}
            onClick={() => onClickDropdownItem(item)}
          >
            {item.cta.title}
          </Link>
        </div>
      )}
    </CollapserThing>
  </>
);

const MenuItem = ({isActive, children}) => (
  <li className={cx('py-1.5 px-2.5 mx-1 text-lg rounded-lg', {'text-white bg-indigo': isActive})}>
    {children}
  </li>
);

const AccountMenuItem = ({children}) => <li className="py-1 px-5 text-lg">{children}</li>;

export default MobileNavigation;

import React, {Fragment} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {Listbox, Transition} from '@headlessui/react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {ChevronIcon, SimpleCheckIcon, InformationIcon} from './svg';

const DropDownMultiSelect = ({
  options,
  onChange,
  placeholderText,
  dropDownLabel,
  tooltip,
}) => {
  const selections = options.filter((option) => option.selected);
  const formatSelections = () => {
    if (!selections.length) return placeholderText;
    if (selections.length > 1) return `${selections[0].label} & ${selections.length - 1} more`;
    return selections[0].label;
  };

  return (
    <Listbox value={options} onChange={onChange}>
      {({open}) => (
        <>
          {dropDownLabel && <Listbox.Label className="block text-sm font-medium text-gray-700">{dropDownLabel}</Listbox.Label>}
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate overflow-ellipsis">{formatSelections()}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({active}) => cx(
                      active ? 'text-white bg-indigo-600' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-8 pr-4',
                    )}
                    value={option}
                  >
                    {({active}) => (
                      <>
                        <span className={cx(option.selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.label}
                          {tooltip && (
                          <OverlayTrigger
                            placement="top"
                            overlay={(<Tooltip>{option.description}</Tooltip>)}
                          >
                            <span>
                              <InformationIcon className="hidden mx-1 sm:h-4 sm:w-4 sm:inline" />
                            </span>
                          </OverlayTrigger>
                          )}
                        </span>
                        {option.selected ? (
                          <span
                            className={cx(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <SimpleCheckIcon className="h-6 w-6" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

DropDownMultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
  })),
};

DropDownMultiSelect.defaultProps = {
  options: [],
};

export default DropDownMultiSelect;

import React from 'react';
import Script from 'next/script';

import {IS_PROD} from '../constants/config';

export const GoogleAnalyticsScript = ({currentUser}) => (currentUser ? (
  <Script id="google-analytics">
    {`if (window.dataLayer) window.dataLayer.push({'userId': '${currentUser.id}'});`}
  </Script>
) : null);

export const FomoScript = ({hasSubscription}) => ((!hasSubscription && IS_PROD) ? (
  <Script
    type="text/javascript"
    src="https://load.fomo.com/api/v1/MrmIKDTF-LZfzEMJbykZKg/load.js"
  />
) : null);

export const ProfitWellScript = ({currentUser}) => (
  <Script
    id="profitwell-js"
    data-pw-auth="f392af39f76e80b53caa8dbadedf34e5"
  >
    {`(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
      a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
      s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
      })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
      profitwell('start', {${currentUser ? `'user_email': '${currentUser.email}'` : ''}});
    `}
  </Script>
);

export const IS_PROD = process.env.NODE_ENV === 'production';
export const IS_BETA = process.env.APP_ENV === 'beta';

export const BASE_URL = IS_PROD ? 'https://www.tryexponent.com' : 'http://localhost:3000';
export const TWITTER_HANDLE = '@tryexponent';

export const LOGROCKET_KEY = 'ozkys8/exponent';

export const RUDDERSTACK_KEY = IS_PROD && !IS_BETA
  ? '23koLiCETR5WLAnRIdO7Aqycewn'
  : '23Gaf2C0nrv57kzB43cNBwc79n3';

export const STRIPE_PUBLIC_KEY = IS_PROD
  ? 'pk_live_avIqegTkj7HdBjlWl1X8GR4Y'
  : 'pk_test_GpA7lrfWsEl3Y2J5YhWn0QaR';

export const SPLIT_KEY = IS_PROD
  ? 'bn7ve84g9mvf1g6r92nb18l20ktlgsv2t9v3'
  : 'imo2omvajc6l4lu38n4k7ts36dq47d3gi6pb';

export const GOOGLE_WEB_CLIENT_ID = '934940858286-m70el7hic17143vaturv3m06b4ai824n.apps.googleusercontent.com';

export const SENTRY_DSN = 'https://fa0f026b85654b05a9b2d89f4899f92e@sentry.io/1245747';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCZk6mz3xZLgz3a4NxHnlRiyS5IDLLrqNI',
  databaseURL: 'https://pramp-prod.firebaseio.com',
  authDomain: 'pramp-prod.firebaseapp.com',
  projectId: 'pramp-prod',
};

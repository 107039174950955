import Router from 'next/router';
import {camelCase} from 'lodash';
import qs from 'query-string';

import {IS_PROD} from '../constants/config';
import Events from '../constants/events';
import {isBot} from './bots';

const events = Object.keys(Events);
let isInitialized = false;
let gtm;

const RUDDERSTACK_PAGE_ENABLED = false;

const updateDrip = (currentUser) => {
  if (currentUser) {
    if (typeof _dcq !== 'undefined') {
    // Pushes subscriber data into Drip
    // eslint-disable-next-line no-undef
      _dcq.push(['identify', {
        email: currentUser.email,
        user_id: currentUser.id,
      }]);
    }
  }
};

const updateRudderstack = async (currentUser, hasSubscription) => new Promise((res, rej) => {
  if (!currentUser) {
    res();
    return;
  }
  try {
    if (window.rudderanalytics) {
      window.rudderanalytics.identify(currentUser.id, {
        // Standard attributes
        email: currentUser.email,
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        avatar: currentUser.profile_img,
        createdAt: currentUser.created_at,

        // Custom attributes
        uuid: currentUser.uuid,
        ssoProvider: currentUser.sso_provider,
        career: currentUser?.profile?.career_tracks,
        hasSubscription,
      }, null, res);
    }
  } catch (e) {
    rej(e);
  }
});

const initializeGTM = () => {
  gtm = (event, data) => {
    if (IS_PROD && window.dataLayer) {
      window.dataLayer.push({event, ...data});
    }
  };
};

const initializeRudderstack = () => {
  if (!window.rudderanalytics) {
    return;
  }
  // rudderstack's recommended SDK installation is via direction insertion of a script tag into
  // src/client/pages/_document.js. For more info:
  // https://github.com/rudderlabs/rudder-analytics-next/blob/main/sample-next-app/pages/_document.js

  const logPageView = () => {
    if (RUDDERSTACK_PAGE_ENABLED) {
      window.rudderanalytics.page();
    }
  };

  logPageView();
  Router.events.on('routeChangeComplete', logPageView);
};

const initializeUserAnalytics = async () => {
  if (isBot()) {
    console.info('[Analytics] Skipping analytics due to user agent');
    return;
  }

  initializeRudderstack();
  initializeGTM();
  isInitialized = true;
};

/**
 * This function is not called directly! Instead it is accessed via
 * the tracker exposed by the useAnalytics() hook.
 * @param {string} eventName Uppercase, snake case name of an event type defined in Events.
 * @param {object} [data] Additional data to send to analytics.
 */
const track = async (eventName, data) => {
  if (!isInitialized) await initializeUserAnalytics();

  if (isBot()) return;

  const {query, url} = qs.parseUrl(Router.asPath);
  const eventData = {...data, path: url, query};

  if (window.rudderanalytics) {
    window.rudderanalytics.track(eventName, eventData);
  }
  gtm(eventName, data);

  if (!IS_PROD) {
    console.info(`[Analytics] Event '${eventName}' ${data ? 'with data' : ''}`, (data || ''));
  }
};

export const updateUserAnalytics = async (currentUser, hasSubscription) => {
  if (!isInitialized) await initializeUserAnalytics();

  if (isBot()) return;

  updateDrip(currentUser);
  await updateRudderstack(currentUser, hasSubscription);
};

// For convenience, we create a camel-cased function for each event name
export const tracker = (properties = {}) => events.reduce((acc, event) => {
  acc[camelCase(event)] = (eventData) => track(Events[event], {...properties, ...eventData});
  return acc;
}, {});

/**
 * To be called after a user has successfully completed a purchased.
 * @param {string|number} total Dollar amount spent on the purchase.
 * @param {string} [email] Email of the purchaser, used for GTM enhanced conversion tracking
 * @param {string} [stripeChargeID] Either from a payment intent or a subscription invoice.
 * @param {string} [stripeCustomerID]
 */
export const trackConversion = async (
  total,
  email,
  stripeChargeID,
  stripeCustomerID,
  sku,
  category,
  currency = 'usd',
) => {
  if (!isInitialized) await initializeUserAnalytics();

  track(Events.PAYMENT_SUCCESS, {
    conversionValue: Number(total),
    transactionId: Date.now(),
    transactionTotal: Number(total),
    currencyCode: currency,
    email,
  });

  // GA4 E-commerce tracking
  // Note: Currently assumes one product type per transaction
  gtm('purchase', {
    ecommerce: {
      transaction_id: stripeChargeID,
      value: Number(total),
      currency,
      items: [{
        item_id: sku,
        item_name: sku,
        item_category: category,
        price: Number(total),
        index: 0,
        quantity: 1,
      }],
    },
  });

  // Tapfiliate conversion events - relies on global 'tap' being defined
  if (typeof window.tap !== 'undefined' && typeof stripeChargeID !== 'undefined' && typeof stripeCustomerID !== 'undefined') {
    window.tap('conversion', stripeChargeID, Number(total), {customer_id: stripeCustomerID, currency});
  } else {
    console.info(`[Tapfiliate] Event 'conversion' with total: ${Number(total)}, charge_id: ${stripeChargeID}, customer_id: ${stripeCustomerID}, currency: ${currency}`);
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import Header, {MinimalHeader} from './Header';
import Footer, {MinimalFooter} from './Footer';
import PageBanner from './PageBanner';
import DocumentHeader from './DocumentHeader';
import GoogleOneTap from './GoogleOneTap';
import {NotificationContainer} from './Notification';
import ConfirmNewAccountModal from './ConfirmNewAccountModal';
import SpecialOfferModal from './SpecialOfferModal';
import {useAppContext} from '../hooks';

const PageContainer = ({
  stickyHeader,
  minimalHeader,
  showPageBanner, // TODO: turn off all banners for PromoLandingPage and duet banner for /practice
  hideFooter,
  minimalFooter,
  hideGoogleOneTap,
  children,
  metadata,
  exitEnabled,
  scrollEnabled,
}) => {
  const {
    currentUser,
    features,
    hasSubscription,
    notifications,
    pendingAccount,
    loading,
  } = useAppContext();

  const showSpecialOffer = (
    !currentUser
    && !hasSubscription
    && !pendingAccount
    && features?.special_offer
    && features?.special_offer !== 'off'
  );

  const HeaderComponent = minimalHeader ? MinimalHeader : Header;
  const FooterComponent = minimalFooter ? MinimalFooter : Footer;

  return (
    <>
      <DocumentHeader />
      {showPageBanner && <PageBanner />}
      <HeaderComponent isSticky={stickyHeader} />
      <GoogleOneTap show={!(hideGoogleOneTap || currentUser || loading)} />
      {pendingAccount && <ConfirmNewAccountModal />}
      {showSpecialOffer && (
        <SpecialOfferModal
          metadata={metadata}
          exitEnabled={exitEnabled}
          scrollEnabled={scrollEnabled}
        />
      )}
      <div className="min-h-screen">
        {children}
      </div>
      {!hideFooter && <FooterComponent />}
      <NotificationContainer notifications={notifications} />
    </>
  );
};

PageContainer.propTypes = {
  minimalHeader: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  showPageBanner: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideGoogleOneTap: PropTypes.bool,
  children: PropTypes.node.isRequired,
  metadata: PropTypes.object,
  exitEnabled: PropTypes.bool,
  scrollEnabled: PropTypes.bool,
};

PageContainer.defaultProps = {
  minimalHeader: false,
  stickyHeader: false,
  showPageBanner: true,
  hideFooter: false,
  hideGoogleOneTap: false,
  metadata: {},
  exitEnabled: true,
  scrollEnabled: false,
};

export default PageContainer;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import cx from 'classnames';
import Link from './Link';

const MAX_COMPANIES = 3;

export const ListAskedAt = ({tags, className}) => {
  if (!tags || !tags.length) {
    return null;
  }

  const askedOnDates = tags.map(({askedOn}) => moment(askedOn));
  const mostRecentOccurence = askedOnDates.length > 0 ? moment.max(askedOnDates) : moment.invalid();

  const imgTag = tags.find((tag) => !!tag.imageUrl);

  return (
    <span className={cx('text-sm font-normal text-gray-500 whitespace-nowrap flex md:items-center', className)}>
      {imgTag && (
        <img
          className="h-4 w-4 mr-1.5 object-contain mt-0.5 md:mt-0"
          alt={`${imgTag.name} logo`}
          src={imgTag.imageUrl}
        />
      )}
      Asked at&nbsp;
      {tags
        .slice(0, MAX_COMPANIES)
        .map((tag, i) => (
          <span className={cx({'hidden md:inline': i > 0})} key={tag.id}>
            {i > 0 && i <= Math.min(tags.length - 1, MAX_COMPANIES - 1) && ', '}
            <Link
              className="hover:text-gray-600"
              href={`/questions?company=${tag.id}`}
              onClick={(e) => e.stopPropagation()}
            >
              {tag.name}
            </Link>
          </span>
        ))}
      &nbsp;
      {tags.length > MAX_COMPANIES && (
        <span className="hidden md:inline">
          +&nbsp;
          {`${tags.length - MAX_COMPANIES} more`}
          &nbsp;
        </span>
      )}
      {mostRecentOccurence.isValid() && (
        <span className="hidden md:inline">
          &nbsp;• &nbsp;
          <time dateTime={mostRecentOccurence.toISOString()}>
            {moment(mostRecentOccurence).fromNow()}
          </time>
        </span>
      )}
    </span>
  );
};

ListAskedAt.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
  })),
};

export const ListAskedAtLogos = ({tags}) => {
  if (!tags || !tags.length) return null;

  const imgTag = tags.find((tag) => !!tag.imageUrl);

  if (!imgTag) return null;

  return (
    <span className="text-gray-600 font-medium flex items-center">
      Asked&nbsp;at
      <img
        className="h-4 w-4 ml-2 object-contain"
        alt={`${imgTag.name} logo`}
        src={imgTag.imageUrl}
      />
    </span>
  );
};

ListAskedAtLogos.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
  })),
};

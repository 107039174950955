import {useEffect, useState} from 'react';
import {throttle} from 'lodash';

const DEFAULT_THROTTLE_MS = 100;
const DEFAULT_DELAY_MS = 5 * 1000; // 5 seconds
const DEFAULT_Y_THRESHOLD = 10;

/**
 * Detects exit intent and triggers callback
 * @param {*} onExit callback when exit is detected
 * @param {Number} throttle? max delay before re-firing exit callback, default 100ms
 */
function useExitIntent(onExit, options = {}, exitEnabled = true) {
  const [startTime, setStartTime] = useState(new Date());

  useEffect(() => {
    const handleExit = throttle((e) => {
      // Only trigger if mouse exited near top of page
      const isTopExit = e.clientY < DEFAULT_Y_THRESHOLD;

      // Only trigger after a delay time
      const duration = new Date() - startTime;
      const delay = options.delay || DEFAULT_DELAY_MS;
      const isPastDelay = duration > delay;

      if (isTopExit && isPastDelay && typeof onExit === 'function') {
        onExit();
      }
    }, options.throttle || DEFAULT_THROTTLE_MS);

    if (exitEnabled) {
      document.body.addEventListener('mouseleave', handleExit);
    }

    return () => {
      if (exitEnabled) {
        document.body.removeEventListener('mouseleave', handleExit);
      }
    };
  }, [exitEnabled]);
}

export default useExitIntent;

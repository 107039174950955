/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import Countdown from 'react-countdown';
import {
  getTimerExpiration,
  getPromotion,
  setDismissed,
  getDismissed,
} from '../util/promo';
import {AppContext} from './AppContext';
import Link from './Link';
import {XIcon} from './svg';

import getContent from '../util/landingPageConfig';
import ConfirmRenewalModal from './ConfirmRenewalModal';
import {tracker} from '../util/UserAnalytics';

class PageBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dismissed: true,
      expiration: null,
      content: null,
    };
  }

  componentDidMount() {
    const promoCode = getPromotion();
    const content = getContent(promoCode);
    const expiration = getTimerExpiration(content, promoCode);
    const bannerId = this.getBannerId();
    const dismissed = bannerId && getDismissed(bannerId);
    this.setState({
      content,
      expiration,
      dismissed,
    });
  }

  dismissBanner = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const bannerId = this.getBannerId();
    setDismissed(bannerId);
    this.setState({dismissed: true});
  };

  getBannerId = () => {
    const promoCode = getPromotion();
    const {pageBanner} = this.context;
    const bannerId = promoCode || pageBanner?.title;
    return bannerId;
  };

  shouldShowPromoBanner = () => {
    const {content, expiration} = this.state;
    const {hasSubscription, catalog} = this.context;
    const expired = expiration === 'expired';
    const bannerId = this.getBannerId();
    const dismissed = this.state.dismissed || getDismissed(bannerId);

    return (
      content
      && content.promoProduct
      && catalog?.[content.promoProduct]
      && !content.hideBanner
      && expiration
      && !expired
      && !hasSubscription
      && !dismissed
    );
  };

  onClickRenewBanner = () => {
    this.setState({renewModalOpen: true});
    tracker().bannerClickRenew();
  };

  handlePayment = () => {
    const {catalog, onCheckout, currentUser} = this.context;
    const {content} = this.state;
    const product = catalog[content.promoProduct];
    const cart = [{id: product.name, quantity: 1}];
    onCheckout(cart, currentUser);
  };

  render() {
    const {pageBanner, catalog} = this.context;
    const {content, expiration, renewModalOpen} = this.state;
    const dismissed = getDismissed(this.getBannerId());

    // Prioritize promotional timer banners
    if (this.shouldShowPromoBanner()) {
      const product = content && catalog?.[content.promoProduct];

      return (
        <div>
          <div
            className="bg-indigo hidden md:block"
            onClick={this.handlePayment}
            role="button"
            tabIndex="0"
          >
            <div className="max-w-8xl mx-auto py-3 pl-3 pr-1 sm:pl-6 lg:pl-8">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <span className="p-2 rounded-lg bg-indigo-900">
                    <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                    </svg>
                  </span>
                  <p className="ml-6 sm:ml-3 font-medium text-white truncate">
                    <span>
                      {content.bannerPrefix || 'Limited time offer:'}
                      {' '}
                    </span>
                    <span className="line-through opacity-50">
                      {`${product.priceString}`}
                    </span>
                    <span>
                      {' '}
                      {content.promoPriceString || product.discountedPriceString}
                      {content.promoTimePeriod}
                    </span>
                  </p>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <p className="mx-3 font-medium text-white truncate">
                    <span>Promotion expires in </span>
                    {expiration && <Countdown date={expiration} />}
                  </p>
                </div>
                <div className="order-4 mt-2 flex-shrink-0 w-full sm:order-3 sm:mt-0 sm:w-auto">
                  {content.landingCtaText && (
                    <button
                      type="button"
                      className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo bg-white hover:bg-indigo-100"
                    >
                      {content.landingCtaText}
                    </button>
                  )}
                </div>
                <div className="order-2 flex-shrink-0 sm:order-4 mr-6 md:mr-0">
                  <button type="button" className="flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white" onClick={this.dismissBanner}>
                    <span className="sr-only">Dismiss</span>
                    <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <span
            className="bg-indigo block md:hidden"
            onClick={this.handlePayment}
            role="button"
            tabIndex="0"
            aria-label="Check out"
          >
            <div className="max-w-7xl mx-auto py-3 pl-3 pr-1 sm:pl-6 lg:pl-8">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <p className="ml-6 sm:ml-3 font-medium text-white truncate">
                    <span>
                      {content.bannerPrefix || 'Limited time offer:'}
                      {' '}
                    </span>
                    <span className="line-through opacity-50">
                      {product.priceString}
                    </span>
                    <span>
                      {' '}
                      {content.promoPriceString || product.discountedPriceString}
                      {content.promoTimePeriod}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </span>
        </div>
      );
    }

    // Show modal if clicking on reactivation banner
    if (pageBanner && pageBanner.name === 'Reactivation') {
      return (
        <>
          <ConfirmRenewalModal
            open={renewModalOpen}
            onClose={() => this.setState({renewModalOpen: false})}
          />
          <button
            type="button"
            className="w-full m-0 flex justify-center items-center bg-indigo text-white font-semibold text-center"
            onClick={this.onClickRenewBanner}
          >
            <span className="py-4 px-2">{pageBanner.title}</span>
          </button>
        </>
      );
    }

    // Show global banners if there is no promotional banner
    if (pageBanner && !(pageBanner.dismissible && dismissed)) {
      return (
        <Link
          className="w-full m-0 flex justify-center items-center bg-indigo text-white font-semibold text-center"
          href={pageBanner.link || '/upgrade'}
          target="_blank"
        >
          <span className="py-4 px-2">{pageBanner.title}</span>
          {pageBanner.dismissible && (
            <button
              type="button"
              aria-label="Close"
              className="mx-2"
              onClick={this.dismissBanner}
            >
              <XIcon fill="white" />
            </button>
          )}
        </Link>
      );
    }

    return null;
  }
}

PageBanner.contextType = AppContext;

export default PageBanner;

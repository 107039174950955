import React, {useEffect, useRef} from 'react';

/**
 * This is a HOC that performs a callback when the user clicks
 * outside the child component. Used for dropdowns where we want to
 * close the dropdown when the user clicks elsewhere.
 */
const ClickContainer = ({onClose, children}) => {
  const ref = useRef(null);
  const escapeListener = (e) => {
    if (e.key === 'Escape' && onClose) {
      onClose();
    }
  };
  const clickListener = (e) => {
    if (!(ref.current).contains(e.target) && onClose) {
      onClose();
    }
  };
  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [onClose]);
  return (
    <div ref={ref}>
      {children}
    </div>
  );
};

export default ClickContainer;

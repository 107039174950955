/* eslint-disable import/prefer-default-export */

import {pick} from 'lodash';
import qs from 'query-string';

import {BASE_URL} from '../constants/config';

/**
 * Returns a canonicalized URL that strips out query params except
 * those in the provided whitelist.
 * @param {String} url
 * @param {Array<string>} [allowedParams]
 */
export function getCanonicalURL(path, allowedParams = []) {
  const url = new URL(path, BASE_URL);
  const params = qs.parse(url.search);
  url.search = qs.stringify(pick(params, allowedParams));
  return url.toString();
}

/** Defines primary SSO providers (not SSO partners)  */
const SocialSSOProviders = ['google', 'facebook', 'linkedin'];

/** Defines SSO providers that should not receive promotional emails */
const PromotionDisabledSSOProviders = [
  'columbia',
  'gatech',
];

/** Defines SSO providers that should not have coaching marketed to them */
const LimitedCoachingSSOProviders = [
  'nyu',
  'umd',
  'gatech',
  'duke-fuqua',
  'columbia',
];

/** Defines SSO providers that should display a message about coaching but not be hidden */
const CoachingBannerSSOProviders = [
  'northwestern',
];

module.exports = {
  SocialSSOProviders,
  PromotionDisabledSSOProviders,
  LimitedCoachingSSOProviders,
  CoachingBannerSSOProviders,
};

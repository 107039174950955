import useAppContext from './useAppContext';
import {NotificationType} from '../components/Notification';

/**
 * Returns various helper functions for interacting with AppContext's notifications.
 *
 * TODO: We could consider making the context be an argument in the future
 * if we need a more flexible hook. See: https://stackoverflow.com/q/55147479/3681279
 */
const useNotifications = () => {
  const {dispatch} = useAppContext();

  const setError = ({
    title = 'Error',
    message = '',
    body = '',
  }) => {
    dispatch({
      type: 'notification',
      payload: {
        type: NotificationType.ERROR,
        title,
        body: message || body,
        key: Date.now(),
      },
    });
  };

  const setSuccess = (title, body) => {
    dispatch({
      type: 'notification',
      payload: {
        title,
        body,
        key: Date.now(),
      },
    });
  };


  return {
    setSuccess,
    setError,
  };
};

export default useNotifications;

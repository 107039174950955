import React from 'react';
import cx from 'classnames';
import _ from 'lodash';

import Link from '../Link';

const toRecentSearch = (search) => {
  switch (search.type) {
    case 'tag': {
      return {
        image: search.item.image_url && (
          <img
            className="h-6 w-6 mr-2 object-contain"
            alt={`${search.item.name} logo`}
            src={search.item.image_url}
          />
        ),
        contents: search.item.name,
      };
    }
    case 'track': {
      return {contents: search.item.title};
    }
    case 'course': {
      return {contents: search.item.title};
    }
    case 'lesson': {
      return {contents: search.item.title};
    }
    case 'resource': {
      return {contents: search.item.title};
    }
    case 'question': {
      return {contents: search.item.data.title};
    }
    case 'coach': {
      return {
        // TODO: Enable once we've fixed the Airtable expiring image problem
        // image: search.item.Photo[0]?.url && (
        //   <img
        //     className="h-6 w-6 mr-2 rounded-full object-cover"
        //     alt={search.item.Name}
        //     src={search.item.Photo[0].url}
        //   />
        // ),
        contents: `${search.item.Name}, ${search.item.Title}`,
      };
    }
    case 'blog': {
      return {contents: search.item.title};
    }
    case 'guide': {
      return {contents: search.item.name};
    }
    default:
      throw new Error(`Unknown search type: ${search.type} rendered in RecentSearch`);
  }
};

const RecentSearch = ({search, onClick}) => {
  const {image, contents} = toRecentSearch(search);

  return (
    <Link
      href={search.href}
      onClick={() => onClick(search)}
      className="flex items-center justify-between mx-3 p-2 text-base text-gray-800 sm:font-medium rounded-lg transition ease-in-out duration-150 hover:bg-gray-50"
    >
      <span className="flex items-center overflow-hidden">
        {image}
        <span className="truncate">{contents}</span>
      </span>
      {search.type !== 'tag' && (
        <span className="text-gray-500 text-xs font-medium uppercase ml-3">{search.type}</span>
      )}
    </Link>
  );
};

const RecentSearches = ({recent = [], onClick, open}) => (recent.length ? (
  <div className={cx(`
    absolute
    top-0
    left-0
    mt-11
    py-3
    w-full
    md:w-screen
    md:max-w-md
    bg-white
    border
    border-gray-200
    rounded-xl
    shadow-lg
    z-40
    origin-top
    transition-all
    ease-in-out
    duration-200
    delay-50
    transform
    translate-y-1`,
  {
    'opacity-100': open,
    visible: open,
    'opacity-0': !open,
    invisible: !open,
  })}
  >
    {recent.map((search) => (
      <RecentSearch
        search={search}
        onClick={onClick}
        key={search.href}
      />
    ))}
  </div>
) : null);

export default RecentSearches;

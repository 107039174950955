import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const FlashMessage = ({
  show, hideCallback, duration, children,
}) => {
  // Intentionally leaves out hideCallback dependency as this shouldn't change
  // over lifespan of FlashMessage
  useEffect(() => {
    if (duration && show) setTimeout(hideCallback, duration);
  }, [duration, show]);

  return (
    <div style={{opacity: show ? 1 : 0, transition: 'opacity 1s'}}>
      {children}
    </div>
  );
};

FlashMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  hideCallback: PropTypes.func,
  duration: PropTypes.number,
  children: PropTypes.node.isRequired,
};

FlashMessage.defaultProps = {
  hideCallback: () => {},
  duration: 2000,
};

export default FlashMessage;

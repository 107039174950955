import React from 'react';
import cx from 'classnames';

import Link from './Link';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';
import Logo from '../../assets/images/logo.svg';
import LogoType from '../../css/images/logotype_black.png';

const HeaderContainer = ({
  isSticky = false,
  isFixed = false,
  className,
  children,
}) => (
  <nav className={cx('font-heading bg-white px-4 lg:px-8 z-10', {
    'sticky top-0 shadow': isSticky,
    'fixed top-0 shadow w-full': isFixed,
    'border-b': !isSticky && !isFixed,
  }, className)}
  >
    <div className="flex items-center justify-between">{children}</div>
  </nav>
);

const HeaderLogo = () => (
  <Link href="/?src=nav" className="flex items-center flex-shrink-0 mr-0.5">
    <img src={Logo.src} alt="Exponent Logo" className="h-6 w-6 mr-2.5" />
    <img src={LogoType.src} alt="Exponent" className="h-5 mt-1 block md:hidden lg:block" />
  </Link>
);

const Header = ({upgradeLink = '/upgrade?src=nav', isSticky = false, isFixed = false}) => (
  <HeaderContainer isSticky={isSticky} isFixed={isFixed}>
    <HeaderLogo />
    <DesktopNavigation upgradeLink={upgradeLink} />
    <MobileNavigation upgradeLink={upgradeLink} />
  </HeaderContainer>
);

export const MinimalHeader = ({rightButton = undefined, ...props}) => (
  <HeaderContainer className="py-4" {...props}>
    <HeaderLogo />
    {rightButton}
  </HeaderContainer>
);

export default Header;

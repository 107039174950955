import React from 'react';

const ErrorPage = () => (
  <div style={{height: '80vh'}} className="flex flex-col items-center justify-center">
    <h1 className="text-xl md:text-2xl font-bold mb-4 text-gray-700">Sorry, something went wrong!</h1>
    {/* Shouldn't use Link component */}
    <a href="/">
      <p className="text-lg font-semibold text-indigo mb-24">Take me back home</p>
    </a>
    <p className="text-sm text-gray-400">
      Want to report an issue?
      {' '}
      <a target="_blank" rel="noreferrer" href="mailto:support@tryexponent.com" className="text-gray-600 font-medium">
        Contact support
      </a>
    </p>
  </div>
);

export default ErrorPage;

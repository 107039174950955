import React from 'react';
import cx from 'classnames';

const FadeOut = ({
  top, right, bottom, left, height = 'h-4', width = 'w-4', color, children, className,
}) => (
  <div className={cx('relative overflow-hidden', className)}>
    {top && <div className={cx('absolute pointer-events-none top-0 w-full bg-gradient-to-b from-white', color, height)} />}
    {right && <div className={cx('absolute pointer-events-none right-0 h-screen bg-gradient-to-l from-white', color, width)} />}
    {bottom && <div className={cx('absolute pointer-events-none bottom-0 w-full bg-gradient-to-t from-white', color, height)} />}
    {left && <div className={cx('absolute pointer-events-none left-0 h-screen bg-gradient-to-r from-white', color, width)} />}
    {children}
  </div>
);
export default FadeOut;

import React, {useState} from 'react';
import {useRouter} from 'next/router';
import Avatar from 'react-avatar';
import cx from 'classnames';

import Button, {ButtonSizes} from './ButtonNew';
import KarmaPill, {KarmaPillSizes} from './KarmaPill';
import DropdownItem from './NavigationDropdownItem';
import Search from './Search';
import Link from './Link';
import {CaretIcon, UpgradeIcon} from './svg';
import Colors from '../constants/colors';
import Menu from '../constants/menu';
import {useAppContext, useAnalytics} from '../hooks';
import {shouldHideCoaching} from '../util';

const DesktopNavigation = ({showActive = true, upgradeLink = '/upgrade?src=nav'}) => {
  const appContext = useAppContext();
  const {tracker} = useAnalytics();
  const {pathname} = useRouter();
  const {currentUser, hasSubscription} = appContext;

  const shouldBeActiveFor = (paths = []) => showActive && paths.includes(pathname);
  const shouldShow = (item) => !item.onlyMobile && !item.shouldHide?.(appContext);

  const trackClick = (item) => {
    tracker.headerClickMenu({
      platform: 'desktop',
      title: item.title,
      link: item.link,
    });
  };

  // The green arrow icon on desktop navigation
  let actionLink = {
    link: upgradeLink,
    text: 'Upgrade',
  };

  if (currentUser?.isOrgAdmin) {
    actionLink = {
      link: '/analytics?src=nav',
      text: 'View analytics',
    };
  } else if (hasSubscription && !shouldHideCoaching(currentUser)) {
    actionLink = {
      link: '/coaching/start?src=nav',
      text: 'Get coaching',
    };
  } else if (hasSubscription && shouldHideCoaching(currentUser)) {
    actionLink = { // hides actionLink
      link: null,
      text: null,
    };
  }

  return (
    <div className="hidden md:flex w-full justify-between">
      <ul className="flex items-center lg:ml-5">
        {Menu.primaryItems.filter(shouldShow).map((item) => ((item.items || item.gridItems) ? (
          <Dropdown
            item={item}
            isActive={shouldBeActiveFor(item.activePaths)}
            onClick={() => trackClick(item)}
            onClickDropdownItem={(i) => trackClick(i)}
            key={item.title}
          />
        ) : (
          <MenuItem
            isActive={shouldBeActiveFor(item.activePaths)}
            key={item.title}
          >
            {item.children ? item.children : (
              <Link
                href={item.link}
                onClick={() => trackClick(item)}
                forceAnchor={item.forceAnchor}
              >
                {item.title}
              </Link>
            )}
          </MenuItem>
        )))}
      </ul>
      <ul className="flex items-center">
        <Search className="absolute right-0 -top-5 w-60 mr-2" />

        {currentUser ? (
          <>
            <MenuItem>
              {actionLink.link && (
                <Link href={actionLink.link} style={{color: '#10B981'}}>
                  <>
                    <UpgradeIcon className="h-5 w-5 mb-1 mr-1 flex-shrink-0" />
                    {actionLink.text}
                  </>
                </Link>
              )}
            </MenuItem>
            <KarmaPill
              score={currentUser.karma}
              size={KarmaPillSizes.LARGE}
              onClick={() => tracker.karmaClickScore({source: 'nav'})}
            />
            <Dropdown
              item={{
                children: <Avatar
                  name={currentUser.displayName}
                  src={currentUser.profile_img || ''}
                  color="#f0f0f0"
                  fgColor="#000000"
                  size={32}
                  round
                />,
                link: `/users/${currentUser.uuid}?src=nav`,
                items: Menu.accountItems.filter(shouldShow),
                align: 'right',
                hideCaret: true,
              }}
              onClickDropdownItem={(i) => trackClick(i)}
            />
          </>
        ) : (
          <>
            <MenuItem>
              <Link href="/login?src=nav" onClick={() => tracker.headerClickLogin()}>
                Log in
              </Link>
            </MenuItem>
            <MenuItem>
              <Button
                size={ButtonSizes.LARGE}
                href="/signup?src=nav"
                onClick={() => tracker.headerClickSignup()}
              >
                Sign up
              </Button>
            </MenuItem>
          </>
        )}
      </ul>
    </div>
  );
};

const Dropdown = ({
  item,
  isActive = false,
  onClick,
  onClickDropdownItem,
}) => {
  const [open, setOpen] = useState(false);
  const openDropdown = () => setOpen(true);
  const closeDropdown = () => setOpen(false);
  const handleClick = () => {
    if (onClick) onClick();
    setOpen(false);
  };
  const handleDropdownItemClick = (dropdownItem) => {
    if (onClickDropdownItem) onClickDropdownItem(dropdownItem);
    setOpen(false);
  };

  return (
    <div onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
      <MenuItem isActive={isActive} className="relative">
        <Link
          href={item.link || '#'}
          onClick={onClick}
          forceAnchor={item.forceAnchor}
        >
          <span className="whitespace-nowrap">
            {item.children || item.title}
            {(item.items || item.gridItems) && !item.hideCaret && (
              <CaretIcon
                width={9}
                height={11}
                className="ml-2 transform -rotate-90"
                fill={isActive ? Colors.Purple : undefined}
              />
            )}
          </span>
        </Link>
        {(item.items || item.gridItems) && (
          <div
            className={cx(`
              absolute
              top-0
              mt-12
              bg-white
              border
              border-gray-200
              rounded-xl
              shadow-lg
              z-50
              origin-top
              transition-all
              ease-in-out
              duration-200
              delay-50
              transform
              translate-y-1
              overflow-hidden`,
            {
              'py-3': !item.gridItems,
              'pt-3 pb-0': item.gridItems,
              '-left-6': item.align === 'left',
              '-right-2': item.align === 'right',
              'opacity-100': open,
              visible: open,
              'opacity-0': !open,
              invisible: !open,
            })}
          >
            <div className="flex mx-3">
              {item.gridItems && (
                <div className="max-w-xl" style={{width: '70vw'}}>
                  {item.gridItemsTitle && <div className="text-xs font-semibold text-gray-400 uppercase p-2">{item.gridItemsTitle}</div>}
                  <ul className="grid grid-cols-2 w-full">
                    {item.gridItems.map((gridItem) => (
                      <DropdownItem
                        key={gridItem.title}
                        item={gridItem}
                        onClick={() => handleDropdownItemClick(gridItem)}
                      />
                    ))}
                  </ul>
                </div>
              )}
              {item.items && (
                <div className="">
                  {item.itemsTitle && <div className="text-xs font-semibold text-gray-400 uppercase p-2">{item.itemsTitle}</div>}
                  <ul>
                    {item.items.map((dropdownItem) => (
                      <div className={cx({'md:w-96': dropdownItem.description})}>
                        <DropdownItem
                          key={dropdownItem.title}
                          item={dropdownItem}
                          onClick={() => handleDropdownItemClick(dropdownItem)}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {item.cta && (
              <div className="w-full flex justify-center bg-gray-100 mt-2">
                <div className="p-2 lg:p-3">
                  <Link
                    className="block text-indigo text-base font-medium py-1 hover:text-indigo focus:text-indigo"
                    href={item.cta.link}
                    onClick={handleClick}
                  >
                    {item.cta.title}
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}

      </MenuItem>
    </div>
  );
};

export const MenuItem = ({className = '', children, isActive}) => {
  const alwaysClasses = 'flex items-center text-base font-medium mx-2.5 pt-1 h-16 border-b-2 border-transparent whitespace-nowrap';
  const activeClasses = 'text-indigo border-indigo';
  return <li className={cx(`${className} ${alwaysClasses}`, {[activeClasses]: isActive})}>{children}</li>;
};

export default DesktopNavigation;

import {useState, useEffect} from 'react';

// a Util function that will conver the absolute width into breakpoints
function getBreakPoint(windowWidth) {
  if (windowWidth) {
    if (windowWidth < 576) {
      return 'sm';
    } if (windowWidth < 768) {
      return 'md';
    } if (windowWidth < 992) {
      return 'lg';
    }
    return 'xlg';
  }
  return undefined;
}

function useWindowSize() {
  const [width, setWidth] = useState(undefined);
  const [height, setHeight] = useState(undefined);
  const [breakpoint, setBreakpoint] = useState(undefined);

  useEffect(() => {
    // a handler which will be called on change of the screen resize
    function handleResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setBreakpoint(getBreakPoint(window.innerWidth));
    }

    handleResize();

    // register the window resize listener
    window.addEventListener('resize', handleResize);

    // unregister the listener on destroy of the hook
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {breakpoint, width, height};
}

export default useWindowSize;

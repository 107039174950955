import {loadStripe} from '@stripe/stripe-js/pure';

import {createCheckoutSession, createMasterclassCheckoutSession} from '../api/api';
import {IS_PROD, STRIPE_PUBLIC_KEY} from '../constants/config';
import {tracker} from './UserAnalytics';

// This is a helper that will push to GTM datalayer in production
const gtm = (data) => {
  if (!IS_PROD) return;
  window.dataLayer.push(data);
};

export default class Checkout {
  constructor(catalog) {
    this.catalog = catalog;
  }

  /**
   * Sets up checkout by async loading the Stripe script
   */
  initialize = async () => {
    this.stripe = await loadStripe(STRIPE_PUBLIC_KEY);
  };

  getStripe = () => this.stripe;

  /**
   * @param {Array<{id: string, quantity: number}>} products list of products
   * @param {object} user
   * @param {oject} options
   */
  openCheckout = async (
    products,
    user,
    {
      email, tag, currency = 'USD', isGift, giftRecipient, giftDate, cancelUrl, resumeRequestId,
    } = {},
    onSuccess,
  ) => {
    if (!this.stripe) await this.initialize();

    if (!products || !products.length) {
      throw Error('Missing products');
    }

    // We assume the first item is the 'main' product for tracking purposes
    const mainProduct = products[0].id;
    tracker().upgradeClickBuy({product: mainProduct, tag});

    try {
      gtm({
        event: 'open_checkout',
        productName: mainProduct,
        email: email || user?.email || '',
      });

      // Sends product view data to Drip
      const {price, description} = this.catalog[mainProduct];

      if (typeof _dcq !== 'undefined') {
        // eslint-disable-next-line no-undef
        _dcq.push(
          [
            'track', 'Viewed a Product',
            {
              product_id: mainProduct,
              name: description,
              price,
              categories: 'Checkout',
              currency,
            },
          ],
        );
      }

      const {checkoutSessionID} = await createCheckoutSession(products, {
        email,
        tag,
        currency,
        isGift,
        giftRecipient,
        giftDate,
        cancelUrl,
        resumeRequestId,
      });

      if (onSuccess) onSuccess();

      const result = await this.stripe.redirectToCheckout({sessionId: checkoutSessionID});

      if (result.err) {
        this.handleError(result.err);
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  /**
   * @param {object} user
   * @param {oject} options
   */
  openMasterclassCheckout = async (user, {name, id}) => {
    if (!this.stripe) await this.initialize();

    // tracker.upgradeClickBuy({product: mainProduct, tag}); TODO

    try {
      // gtm({
      //   event: 'open_checkout',
      //   productName: masterclassId,
      //   email: user ? user.email : '',
      // });

      // TODO: Okay that we removed _dcq Drip tagging here?

      const {checkoutSessionID} = await createMasterclassCheckoutSession(name, id);
      const result = await this.stripe.redirectToCheckout({sessionId: checkoutSessionID});

      if (result.err) {
        this.handleError(result.err);
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  handleError = (err) => {
    // TODO: Report error and display to user
    // eslint-disable-next-line no-alert
    alert('Sorry, there was an error with your payment. Please contact support@tryexponent.com');
    throw err;
  };
}

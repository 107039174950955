import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import cx from 'classnames';
import {useRouter} from 'next/router';
import _ from 'lodash';

import RecentSearches from './RecentSearches';
import SearchResults from './SearchResults';
import ClickContainer from '../ClickContainer';
import {StyledTextInput} from '../Forms';
import {SearchIcon, XIcon} from '../svg';
import {getSearchResults} from '../../api/api';
import {useAnalytics} from '../../hooks';

const RECENT_SEARCH_KEY = 'RECENT_SEARCHES';
const NUM_RECENT_SEARCHES = 6;

const Search = ({
  className,
  onClick = _.noop,
  onExpand = _.noop,
}) => {
  const [value, setValue] = useState('');
  const [results, setResults] = useState(null);
  const [recent, setRecent] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const searchBarRef = useRef(null);
  const router = useRouter();
  const {tracker} = useAnalytics();

  // Reset state of Search (except not recent searches, because we usually want to persist that)
  const resetState = () => {
    setValue('');
    setExpanded(false);
    setResults(null);
  };

  // Initialize recent searches
  useEffect(() => {
    const recentSearches = JSON.parse(localStorage.getItem(RECENT_SEARCH_KEY)) || [];
    setRecent(recentSearches);
  }, []);

  // Keyboard shortcut listener. Cmd/Ctrl + k to focus search bar
  useEffect(() => {
    const searchShortcutListener = (e) => {
      if ((e.metaKey && e.key === 'k') || (e.ctrlKey && e.key === 'k')) {
        e.preventDefault();
        searchBarRef.current.focus();
      }
    };

    if (searchBarRef.current) window.addEventListener('keydown', searchShortcutListener);

    return () => {
      window.removeEventListener('keydown', searchShortcutListener);
    };
  }, [searchBarRef]);

  // Always focus the search bar when expanded
  useEffect(() => {
    onExpand(expanded);
    if (expanded) searchBarRef.current.focus();
  }, [expanded]);

  // Reset on route change
  useEffect(() => {
    resetState();
  }, [router.asPath]);

  const completeSearch = useCallback(_.debounce(async (query) => {
    let newResults = null;
    if (query) {
      tracker.searchQuery({search: query});
      newResults = await getSearchResults({query});
    }
    setResults(newResults);
  }, 500), []);

  const onChange = (e) => {
    completeSearch(e.target.value);
    setValue(e.target.value);
    setExpanded(e.target.value !== '');
  };

  /**
   * @param {Object} result
   * @param {string} result.type
   * @param {string} result.href
   * @param {Object} result.item
   */
  const updateRecentSearches = (result) => {
    const recentSearches = JSON.parse(localStorage.getItem(RECENT_SEARCH_KEY)) || [];
    const newRecentSearches = [
      result,
      ...recentSearches.filter((search) => search.href !== result.href),
    ].slice(0, NUM_RECENT_SEARCHES);

    setRecent(newRecentSearches);
    localStorage.setItem(RECENT_SEARCH_KEY, JSON.stringify(newRecentSearches));
  };

  const handleClickResult = (result) => {
    tracker.searchClickResult({type: result.type, href: result.href});
    updateRecentSearches(result);
    onClick(result);
    resetState();
  };

  const handleClickRecentSearch = (result) => {
    tracker.searchClickRecent({type: result.type, href: result.href});
    updateRecentSearches(result);
    onClick(result);
    resetState();
  };

  return (
    <ClickContainer onClose={expanded ? () => setExpanded(false) : null}>
      <div className="relative">
        <div className={className}>
          <div
            className={cx({'md:hidden xl:block': !expanded})}
            style={{boxShadow: '-13px 0px 5px -2px white'}}
          >
            <StyledTextInput
              placeholder="Search"
              className="pl-10"
              value={value}
              onChange={onChange}
              onFocus={() => setExpanded(true)}
              ref={searchBarRef}
            />

            <SearchIcon className="absolute m-auto top-0 bottom-0 left-3 h-5 w-5 pointer-events-none" />

            {(value || expanded) && (
            <button
              type="button"
              aria-label="Clear"
              onClick={resetState}
              className="absolute flex m-auto top-0 bottom-0 right-3 h-5 w-5"
            >
              <XIcon fill="#999" />
            </button>
            )}
          </div>

          {expanded && results && (
            <SearchResults
              results={results}
              query={value}
              onClick={handleClickResult}
            />
          )}

          <RecentSearches
            recent={recent}
            onClick={handleClickRecentSearch}
            open={expanded && !results}
          />
        </div>

        {/* Fallback search icon to display on medium width screens */}
        <button
          type="button"
          aria-label="Search"
          onClick={() => setExpanded(true)}
          className={cx('ml-2 mr-0.5 mb-0.5', {
            hidden: expanded,
            'hidden md:block xl:hidden': !expanded,
          })}
        >
          <SearchIcon stroke="#444" strokeWidth="3" className="h-5 w-5" />
        </button>
      </div>
    </ClickContainer>
  );
};

export default Search;

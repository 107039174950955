// List of blocked user agent strings
export const blockedUserAgents = [
  'headlesschrome',
  'ahrefsbot',
  'baiduspider',
  'bingbot',
  'bingpreview',
  'facebookexternal',
  'petalbot',
  'pinterest',
  'screaming frog',
  'yahoo! slurp',
  'yandexbot',
  'adsbot-google',
  'apis-google',
  'duplexweb-google',
  'feedfetcher-google',
  'google favicon',
  'google web preview',
  'google-read-aloud',
  'googlebot',
  'googleweblight',
  'mediapartners-google',
  'storebot-google',
];

export const isBot = (userAgent) => {
  const ua = (userAgent || navigator.userAgent).toLowerCase();
  for (let i = 0; i < blockedUserAgents.length; i++) {
    if (ua.includes(blockedUserAgents[i])) {
      return true;
    }
  }
  return false;
};

import React, {useEffect} from 'react';
import _ from 'lodash';
import cx from 'classnames';

import Highlighted from './Highlighted';
import Button, {ButtonTypes} from '../ButtonNew';
import {ChicletsContainer} from '../Chiclets';
import {ListAskedAt} from '../ListAskedAt';
import Link from '../Link';
import {StoryBookIcon} from '../svg';
import {useAppContext} from '../../hooks';
import {shouldHideCoaching} from '../../util';

const PREVIEW_LIMIT = 6;

// eslint-disable-next-line jsx-a11y/heading-has-content
const SearchResultTitle = ({className, ...props}) => <h4 className={cx('text-gray-600 text-base font-medium leading-5 mb-1', className)} {...props} />;

const SearchResultBody = ({className, ...props}) => <div className={cx('text-gray-500 leading-5', className)} {...props} />;

const PreviewSection = ({title, children}) => (
  <div className="mb-10">
    <h3 className="text-xl font-bold text-gray-900 mb-5">{title}</h3>
    <div className="grid grid-cols-2 gap-x-6 gap-y-4 md:grid-cols-3 overflow-hidden">
      {children}
    </div>
  </div>
);

const TrackResult = ({result: {item, matches}, onClick}) => {
  const href = `/courses/${item.slug}`;
  return (
    <Link
      href={href}
      onClick={() => onClick({type: 'track', href, item})}
    >
      <div className="flex justify-center h-20 items-center bg-gray-100 rounded-md overflow-hidden mb-3">
        {item.thumbnail
          ? <img src={item.thumbnail} className="w-full h-full object-cover" loading="lazy" width="400" height="200" alt={item.title} />
          : <StoryBookIcon className="w-10 h-10 text-gray-400" />}
      </div>
      <SearchResultTitle>
        <Highlighted text={item.title} match={_.find(matches, {key: 'title'})} />
      </SearchResultTitle>
      <SearchResultBody>
        <Highlighted
          text={item.description}
          match={_.find(matches, {key: 'description'})}
          maxCharPadding={30}
        />
      </SearchResultBody>
    </Link>
  );
};

const CourseResult = ({result: {item, matches}, onClick}) => {
  const href = `/courses/${item.slug}`;
  return (
    <Link
      href={href}
      onClick={() => onClick({type: 'course', href, item})}
    >
      <div className="flex justify-center h-20 items-center bg-gray-100 rounded-md overflow-hidden mb-3">
        {item.thumbnail
          ? <img src={item.thumbnail} className="w-full h-full object-cover" loading="lazy" width="400" height="200" alt={item.title} />
          : <StoryBookIcon className="w-10 h-10 text-gray-400" />}
      </div>
      <SearchResultTitle>
        <Highlighted text={item.title} match={_.find(matches, {key: 'title'})} />
      </SearchResultTitle>
      <SearchResultBody>
        <Highlighted
          text={item.description}
          match={_.find(matches, {key: 'description'})}
          maxCharPadding={30}
        />
      </SearchResultBody>
    </Link>
  );
};

const LessonResult = ({result: {item, matches}, onClick}) => {
  const href = `/courses/${item.course}/${item.id}`;
  return (
    <Link
      href={href}
      onClick={() => onClick({type: 'lesson', href, item})}
    >
      <SearchResultTitle>
        <Highlighted text={item.title} match={_.find(matches, {key: 'title'})} />
      </SearchResultTitle>
    </Link>
  );
};

const ResourceResult = ({result: {item, matches}, onClick}) => {
  const href = `/resources/${item.id}`;
  return (
    <Link
      href={href}
      onClick={() => onClick({type: 'resource', href, item})}
    >
      <SearchResultTitle>
        <Highlighted text={item.title} match={_.find(matches, {key: 'title'})} />
      </SearchResultTitle>
    </Link>
  );
};

const QuestionResult = ({result: {item, matches}, onClick}) => {
  const href = `/questions/${item.id}`;
  return (
    <Link
      href={href}
      onClick={() => onClick({type: 'question', href, item})}
      className="block mb-4"
    >
      <SearchResultTitle>
        <Highlighted
          text={item.data.title}
          match={_.find(matches, {key: 'data.title'})}
          maxCharPadding={100}
        />
      </SearchResultTitle>
      <ListAskedAt tags={item.companies} />
    </Link>
  );
};

const CoachResult = ({result: {item, matches}, onClick}) => {
  const href = `/coach/${item.slug}`;
  return (
    <Link
      href={href}
      onClick={() => onClick({type: 'coach', href, item})}
    >
      {/* TODO: Ensure coach images work consistently */}
      {/* <img
        src={item.Photo[0]?.url}
        className="w-20 h-20 rounded-full object-cover"
        alt={item.Name}
      /> */}
      <SearchResultTitle>
        <Highlighted text={item.Name} match={_.find(matches, {key: 'Name'})} />
        {', '}
        <Highlighted text={item.Title} match={_.find(matches, {key: 'Title'})} />
      </SearchResultTitle>
      <SearchResultBody>
        <Highlighted
          text={item['Brief Bio']}
          match={_.find(matches, {key: 'Brief Bio'})}
          maxCharPadding={30}
        />
      </SearchResultBody>
    </Link>
  );
};

const BlogResult = ({result: {item, matches}, onClick}) => {
  const href = `/blog/${item.slug}`;
  return (
    <Link
      href={href}
      onClick={() => onClick({type: 'blog', href, item})}
    >
      <div className="flex justify-center h-20 items-center bg-gray-100 rounded-md overflow-hidden mb-3">
        {item.feature_image
          ? <img src={item.feature_image} className="w-full h-full object-cover" loading="lazy" width="400" height="200" alt={item.title} />
          : <StoryBookIcon className="w-10 h-10 text-gray-400" />}
      </div>
      <SearchResultTitle>
        <Highlighted text={item.title} match={_.find(matches, {key: 'title'})} />
      </SearchResultTitle>
      <SearchResultBody>
        <Highlighted
          text={item.excerpt}
          match={_.find(matches, {key: 'excerpt'})}
          maxCharPadding={30}
        />
      </SearchResultBody>
    </Link>
  );
};

const GuideResult = ({result: {item, matches}, onClick}) => {
  const href = `/guides/${item.slug}`;
  return (
    <Link
      href={href}
      onClick={() => onClick({type: 'guide', href, item})}
    >
      <div className="flex justify-center h-20 items-center bg-gray-100 rounded-md overflow-hidden mb-3">
        {item.feature
          ? <img src={item.thumbnail} className="w-full h-full object-cover" loading="lazy" width="400" height="200" alt={item.title} />
          : <StoryBookIcon className="w-10 h-10 text-gray-400" />}
      </div>
      <SearchResultTitle>
        <Highlighted text={item.name} match={_.find(matches, {key: 'name'})} />
      </SearchResultTitle>
      <SearchResultBody>
        <Highlighted
          text={item.intro}
          match={_.find(matches, {key: 'intro'})}
          maxCharPadding={30}
        />
      </SearchResultBody>
    </Link>
  );
};

const TagResult = ({result: {item}, onClick}) => {
  const href = `/${item.type === 'company' ? 'companies' : 'roles'}/${item.id}`;
  return (
    <Button
      buttonType={ButtonTypes.SECONDARY}
      onClick={() => onClick({type: 'tag', href, item})}
      href={href}
      className="whitespace-nowrap rounded-full flex-shrink-0"
    >
      {item.image_url
         && <img src={item.image_url} className="w-5 h-5 mr-2 object-contain" alt={item.name} />}
      {item.name}
    </Button>
  );
};


const SearchResults = ({results, query, onClick}) => {
  const {currentUser} = useAppContext();

  // Prevent scrolling the page when SearchResults open
  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  const curriculumResults = [
    ...results.tracks.map((result) => <TrackResult result={result} onClick={onClick} />),
    ...results.courses.map((result) => <CourseResult result={result} onClick={onClick} />),
    ...results.lessons.map((result) => <LessonResult result={result} onClick={onClick} />),
    ...results.resources.map((result) => <ResourceResult result={result} onClick={onClick} />),
  ].slice(0, PREVIEW_LIMIT);

  const ugcResults = [
    ...results.questions.map((result) => <QuestionResult result={result} onClick={onClick} />),
    // ...results.comments.map((result) => <CommentResult result={result} onClick={onClick} />),
  ].slice(0, PREVIEW_LIMIT);

  const coachingResults = shouldHideCoaching(currentUser) ? [] : [
    ...results.coaches.map((result) => <CoachResult result={result} onClick={onClick} />),
  ].slice(0, PREVIEW_LIMIT);

  const marketingResults = [
    ...results.blogPosts.map((result) => <BlogResult result={result} onClick={onClick} />),
    ...results.guides.map((result) => <GuideResult result={result} onClick={onClick} />),
  ].slice(0, PREVIEW_LIMIT);

  const emptyResults = _.reduce(results, (acc, value) => acc + value.length, 0) === 0;
  // const showSeeMore = false; // TODO

  return (
    <div
      className="fixed md:top-16 left-0 right-0 bottom-0 z-40 pt-4 pb-8 px-4 md:px-8 bg-white overflow-scroll"
      style={{top: '6.8rem'}}
    >
      {emptyResults ? (
        <div className="flex items-center justify-center w-full h-full">
          <p className="text-gray-600 text-base font-medium leading-snug">
            {`Sorry, we couldn't find anything for "${query}"`}
          </p>
        </div>
      ) : (
        <div className="max-w-2xl mx-auto mb-8">
          {results.tags?.length > 0 && (
            <>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Companies</h3>
              <ChicletsContainer className="mb-4">
                {results.tags?.map((result) => <TagResult result={result} onClick={onClick} />)}
              </ChicletsContainer>
            </>
          )}

          {curriculumResults.length > 0 && (
            <PreviewSection title="Courses">{curriculumResults}</PreviewSection>
          )}

          {ugcResults.length > 0 && (
            <>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Interview Questions</h3>
              <div>{ugcResults}</div>
            </>
          )}

          {coachingResults.length > 0 && (
            <PreviewSection title="Coaching">{coachingResults}</PreviewSection>
          )}

          {marketingResults.length > 0 && (
            <PreviewSection title="Blog">{marketingResults}</PreviewSection>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchResults;

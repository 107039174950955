import {useState, useEffect} from 'react';
import {throttle} from 'lodash';

/**
 * Returns the current window.pageYOffset
 *
 * I tried to find a good way to avoid having multiple usages of this
 * hook create multiple scroll listeners, but failed to do so. I think
 * for the time being, we simply avoid placing many useScrollPositions
 * on the same page
 */
const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState();

  const updatePosition = throttle(() => {
    setScrollPosition(window.pageYOffset);
  }, 50);

  useEffect(() => {
    updatePosition();
    window.addEventListener('scroll', updatePosition);
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

export default useScrollPosition;

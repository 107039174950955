import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import {useRouter} from 'next/router';

import {BASE_URL, TWITTER_HANDLE} from '../constants/config';

const DocumentHeader = (props) => {
  const {
    imgAlt,
    imgPath,
    isFree,
    metaDescription,
    socialDescription,
    title,
    canonicalUrl,
    noindex,
  } = props;

  const router = useRouter();

  const appendedIsAccessibleForFree = {
    isAccessibleForFree: isFree,
    hasPart: {
      '@type': 'WebPageElement',
      isAccessibleForFree: isFree,
      cssSelector: '.paywall',
    },
  };

  const fullTitle = title ? `${title} - Exponent` : null;

  // imgPath can be relative or absolute
  const imgUrl = imgPath ? new URL(imgPath, BASE_URL) : null;

  return (
    <Head>
      {fullTitle && <title key="title">{fullTitle}</title>}
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" key="viewport" />
      {metaDescription && <meta name="description" content={metaDescription} key="description" />}

      {/* Render canonical after router is ready as router.asPath may not be available */}
      {(router.isReady && canonicalUrl) ? <link rel="canonical" href={canonicalUrl} key="canonical" /> : null}

      {/* OG tags */}
      <meta property="og:type" content="website" key="og:type" />
      <meta property="og:site_name" content="Exponent" key="og:site_name" />
      {fullTitle && <meta property="og:title" content={fullTitle} key="og:title" />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} key="og:url" />}
      {imgUrl && <meta property="og:image" content={imgUrl} key="og:image" />}
      {imgAlt && <meta property="og:image:alt" content={imgAlt} key="og:image:alt" />}
      {socialDescription && <meta property="og:description" content={socialDescription} key="og:description" />}

      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
      <meta name="twitter:site" content={TWITTER_HANDLE} key="twitter:site" />
      {canonicalUrl && <meta name="twitter:url" content={canonicalUrl} key="twitter:url" />}
      {fullTitle && <meta name="twitter:title" content={fullTitle} key="twitter:title" />}
      {socialDescription && <meta property="twitter:description" content={socialDescription} key="twitter:description" />}
      {imgUrl && <meta property="twitter:image" content={imgUrl} key="twitter:image" />}
      {imgAlt && <meta property="twitter:image:alt" content={imgAlt} key="twitter:image:alt" />}

      {/* web-crawler flag */}
      {noindex
        ? (<meta name="robots" content="noindex" key="noindex" />)
        : null}
      {isFree
        ? null
        : (<meta name="robots" content="noarchive" key="noarchive" />)}
      <script type="application/ld+json" key="paywall">
        {JSON.stringify(appendedIsAccessibleForFree)}
      </script>
    </Head>
  );
};

DocumentHeader.propTypes = {
  imgAlt: PropTypes.string,
  imgPath: PropTypes.string,
  isFree: PropTypes.bool,
  metaDescription: PropTypes.string,
  socialDescription: PropTypes.string,
  title: PropTypes.string,
  canonicalUrl: PropTypes.string,
  noindex: PropTypes.bool,
};

DocumentHeader.defaultProps = {
  imgAlt: null,
  imgPath: null,
  isFree: true,
  metaDescription: null,
  socialDescription: null,
  title: null,
  canonicalUrl: null,
  noindex: false,
};

export default DocumentHeader;

import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import router from 'next/router';

import Link from './Link';
import Button, {ButtonSizes} from './ButtonNew';
import {createUserFromPramp} from '../api/api';
import {useAppContext, useAnalytics} from '../hooks';
import Migration from '../../assets/images/duet/migration.png';

const ConfirmNewAccountModal = () => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const {
    pendingAccount: {
      content,
      iv,
      email,
      firstName,
    },
  } = useAppContext();
  const {tracker} = useAnalytics();

  useEffect(() => {
    tracker.prampAccountCreationModalView();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    tracker.prampAccountCreationModalSubmit();
    await createUserFromPramp({content, iv});
    router.reload();
  };

  const cancelAccountCreation = () => {
    tracker.prampAccountCreationModalCancel();
    setOpen(false);
  };

  return (
    <Modal
      overlayClassName="fixed inset-0 bg-black bg-opacity-20 flex justify-center items-center z-40"
      className="bg-white w-full h-screen sm:h-auto sm:max-w-md p-10 sm:rounded-xl shadow-xl outline-none z-50 text-center"
      onRequestClose={cancelAccountCreation}
      isOpen={open}
    >
      <img src={Migration.src} alt="Pramp to Exponent migration" className="w-40 object-contain mb-4 mx-auto" />
      <h1 className="text-2xl font-bold text-gray-800 text-center">
        {`Welcome, ${firstName}!`}
      </h1>
      <p className="mt-3 text-base text-gray-600">
        {'You\'re just one step away from your new Exponent account. We\'ll automatically set up a new profile for you with the same email as your Pramp account: '}
        <span className="font-medium">{email}</span>
      </p>
      <div className="mt-6 flex justify-center">
        <Button
          onClick={onSubmit}
          loading={loading}
          size={ButtonSizes.LARGE}
          className="w-full"
        >
          Continue to Exponent
        </Button>
      </div>
      <p className="mt-6 text-sm text-gray-500">
        By continuing you agree to our
        {' '}
        <Link
          href="/tos"
          forceAnchor
          target="_blank"
          className="font-medium text-gray-600"
        >
          terms of service
        </Link>
        {' and '}
        <Link
          href="/privacy"
          forceAnchor
          target="_blank"
          className="font-medium text-gray-600"
        >
          privacy policy
        </Link>
        .
      </p>
    </Modal>
  );
};

export default ConfirmNewAccountModal;

import React from 'react';
import {Rating} from 'react-simple-star-rating';
import cx from 'classnames';

import {StarIcon} from './svg';

// TODO: Make keyboard accessible
const StarRating = ({
  onRating = () => {},
  initialRating = 0,
  count = 5,
  size = 20,
  displayOnly = false,
  allowHalf = false,
  tooltipArray,
  className,
  tooltipClassName,
}) => (
  <Rating
    iconsCount={count}
    initialValue={initialRating}
    onClick={onRating}
    size={size}
    readonly={displayOnly}
    fillIcon={<StarIcon className="h-6 w-6 text-yellow-400" />}
    emptyIcon={<StarIcon className="h-6 w-6 text-gray-300" />}
    allowFraction={allowHalf}
    showTooltip={tooltipArray?.length === count}
    className={cx(className, {'mr-2': tooltipArray?.length > 0})}
    tooltipDefaultText=""
    tooltipClassName={cx(tooltipClassName, 'bg-white text-indigo font-medium text-md p-0 ml-0')}
    tooltipArray={tooltipArray}
  />
);

export default StarRating;

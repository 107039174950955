/* eslint-disable max-len */

/**
 * Timezone that is used to define Duet session times
 * @type {string}
 */
const TIMEZONE = 'America/New_York';

/**
 * 60 minutes
 * @type {number} minutes
 */
const MAX_REJOIN_TIME = 60;

/**
 * 4320 minutes or 3 days
 * @type {number} minutes
 */
const MAX_FEEDBACK_TIME = 4320;

/**
 * Duration of time leading up the session during which users can
 * connect to be matched. 10 minutes.
 * @type {number} seconds
 */
const LEADING_JOIN_TIME = 600;

/**
 * Array of additonal times at which users will be matched, following the
 * scheduled session, to allow for latecomers. Currently, users can be
 * matched 2 minutes and 4 minutes after the scheduled session.
 * @type {array} array of seconds
 */
const TRAILING_MATCH_TIMES = [120, 240];

/**
 * 60 minutes or 3600 seconds
 * @type {number} seconds
 */
const WINDOW_START_TIME = 3600;

/**
 * Period of days for which free users can join max DUET_SESSIONS_PER_PERIOD sessions
 * @type {number} days
 */
const FREE_DUET_SESSION_TTL = 30;

/**
 * Number of duet sessions a free user can join in FREE_DUET_SESSION_TTL
 * @type {number} sessions
 */
const DUET_SESSIONS_PER_PERIOD = 5;

/**
 * Used to determine why duet_request.credit_refunded is true
 * @type {object}
 */
const CreditRefundReasons = {
  ADMIN_REFUNDED: 'admin_refunded',
  POSITIVE_PARTNER_FEEDBACK: 'positive_partner_feedback',
  USER_REPORTED_DIDNT_HAPPEN: 'user_reported_didnt_happen',
};

/**
 * For identifying Twilio screenshare video track
 */
const SCREENSHARE_TRACK = 'SCREENSHARE_TRACK';

/**
 * Array of types of mock interview a user can join
 * @type {array}
 */
const InterviewTypesArray = [
  {
    name: 'Product Management',
    key: 'PM',
    description: 'Practice product sense, estimation, and more with other PMs.',
    tags: {
      role: 'pm',
    },
  },
  {
    name: 'Data Structures & Algorithms',
    key: 'Algorithms',
    description: 'Practice data structures and algorithms questions.',
    tags: {
      role: 'swe',
    },
    new: true,
  },
  {
    name: 'System Design',
    key: 'System-Design',
    description: 'Practice technical architectural questions commonly asked in engineering and TPM interviews.',
    tags: {
      type: 'system-design',
    },
  },
  {
    name: 'Behavioral',
    key: 'Behavioral',
    description: 'Practice common interview questions about your work experiences.',
    tags: {
      type: 'behavioral',
    },
  },
];

/**
 * The keys of InterviewTypesArray's elements mapped to their associated object
 * @type {object}
 */
const InterviewTypes = InterviewTypesArray.reduce((result, type) => ({
  ...result,
  [type.key]: type,
}), {});

/**
 * The possible values for duet_request.status
 *
 * Pre-match statuses
 *
 * pending  - The request has been created, but the user has yet to
 *            connect via websocket.
 * canceled - The pending request was canceled by the user prior to
 *            matching.
 * active   - The user has connected via websocket and is waiting to be
 *            matched. Only status eligible for matching.
 * inactive - The user was previously connected via websocket, but has
 *            disconnected.
 *
 * Post-match statuses
 *
 * match    - The user was matched with another user. A duet_match for
 *            each user's request and a video room was created.
 * no_match - The user was not matched with another user.
 * no_join  - The user was matched with another user, but the user never
 *            ended up joining the session.
 *
 * @type {object}
 */
const RequestStatus = {
  PENDING: 'pending',
  CANCELED: 'canceled',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  MATCH: 'match',
  NO_MATCH: 'no_match',
  NO_JOIN: 'no_join',
};

/**
 * Following a Duet session, a user and their partner can opt in to
 * being introduced over email. These are the possible states of the
 * introduction request.
 *
 * none      - Neither user nor partner has sent intro request
 * pending   - Parner sent intro request to user
 * requested - User sent intro request to partner
 * accepted  - Both user and partner have sent intro requests
 *
 * @type {object}
 */
const PartnerIntroStatus = {
  NONE: 'none',
  PENDING: 'pending',
  REQUESTED: 'requested',
  ACCEPTED: 'accepted',
};

/**
 * Defines the possible feedback fields that can be submitted by a
 * user following a Duet session, including deprecated fields which
 * were used in the past. The actual schema for feedback is defined
 * in the feedback forms on the client.
 *
 * Because old feedback remains accessible to users, for backwards
 * compatibility THIS OBJECT IS APPEND ONLY!
 *
 * key                 - Database key in duet_match.feedback. Should match object key.
 * label               - Label displayed to user in feedback form
 * feedbackLabel       - Label displayed to partner in feedback modal
 * isSharedWithPartner - Whether the feedback is shared with the partner
 * [stars]             - Array of strings associated with star ratings. 0 index is 1 star, 1 index is 2 stars, etc.
 * [radio]             - Array of objects with label and value properties for radio buttons
 * [select]            - Array of strings for select dropdown
 * [written]           - Placeholder text for written feedback
 *
 * @type {object}
 */
const FeedbackFields = {
  // Fields used prior to the Pramp integration
  happened: {
    key: 'happened',
    label: 'Did this session happen?',
    isSharedWithPartner: false,
    radio: [{label: 'Yes', value: true}, {label: 'No', value: false}],
  },
  satisfaction: {
    key: 'satisfaction',
    label: 'How satisfied were you with your interview?',
    isSharedWithPartner: false,
    stars: [
      'Very dissatisfied',
      'Somewhat dissatisfied',
      'Somewhat satisfied',
      'Very satisfied',
    ],
  },
  improve: {
    key: 'improve',
    label: 'How could your partner improve? (This will be shared with your partner)',
    feedbackLabel: 'How could you improve?',
    isSharedWithPartner: true,
    written: 'Comments',
  },
  other: {
    key: 'other',
    label: 'Any additional feedback for Exponent?',
    isSharedWithPartner: false,
    written: 'What issues did you encounter? How can we improve?',
  },

  // Fields added for the Pramp integration
  problem_solving: {
    key: 'problem_solving',
    label: 'How were your partner\'s problem solving skills?',
    feedbackLabel: 'Problem solving',
    isSharedWithPartner: true,
    stars: [
      'Struggling. Had trouble grasping the problem',
      'Decent. Worked out a rough solution',
      'Good. Solved the problem with hints',
      'Amazing. Optimal solution with minimal guidance',
    ],
  },
  coding: {
    key: 'coding',
    label: 'How were your partner\'s coding skills?',
    feedbackLabel: 'Coding skills',
    isSharedWithPartner: true,
    stars: [
      'Beginner. Unfamiliar with some of the basics',
      'Decent. Struggled to get from idea to running code',
      'Strong. Could be made more readable or reusable',
      'Expert. Code is readable, reusable, and maintainable',
    ],
  },
  communication: {
    key: 'communication',
    label: 'How were your partner\'s communication skills?',
    feedbackLabel: 'Communication',
    isSharedWithPartner: true,
    stars: [
      'Weak. Hardly spoke, or was very unclear',
      'Decent. I wasn\'t able to understand some parts',
      'Good. Communicated well',
      'Amazing. Every single bit was crystal clear',
    ],
  },
  do_well: {
    key: 'do_well',
    label: 'What did your partner do well during the session?',
    feedbackLabel: 'Things you did well',
    isSharedWithPartner: true,
    written: 'What are your partner\'s strengths? What impressed you?',
  },
  not_good: {
    key: 'not_good',
    label: 'What could your partner improve?',
    feedbackLabel: 'Areas where you could improve',
    isSharedWithPartner: true,
    written: 'What should your partner improve? How would you advise them to get better?',
  },
  interviewer: {
    key: 'interviewer',
    label: 'How did your partner perform as your interviewer?',
    feedbackLabel: 'Interviewer performance',
    isSharedWithPartner: true,
    stars: [
      'Bad',
      'Okay',
      'Good',
      'Great',
    ],
  },
  challenge_stars: {
    key: 'challenge_stars',
    label: 'How challenging were the question(s) you were asked?',
    isSharedWithPartner: false,
    stars: [
      'Very easy. Piece of cake, really',
      'Easy. I got it pretty fast',
      'Okay. Medium level of difficulty',
      'Difficult. Really had to think about it',
      'Very difficult. Didn\'t know where to start!',
    ],
  },
  challenge: {
    key: 'challenge',
    label: 'How challenging were the question(s) you were asked?',
    isSharedWithPartner: false,
    written: 'Anything else we should know (e.g. have you seen it before, did you like it)?',
  },
  good_match: {
    key: 'good_match',
    label: 'How good a match was your partner for the purpose of this session?',
    isSharedWithPartner: false,
    stars: [
      'Awful. Why did you pair us together?!',
      'Bad. Not a good fit for me',
      'Okay. Kind of in between',
      'Good. Decent match',
      'Great. The right match for me',
    ],
  },
  recommend: {
    key: 'recommend',
    label: 'Based on today\'s session, how likely are you to recommend Exponent\'s peer mock interviews to a friend?',
    isSharedWithPartner: false,
    radio: [
      {label: 0, value: 0}, {label: 1, value: 1}, {label: 2, value: 2}, {label: 3, value: 3}, {label: 4, value: 4}, {label: 5, value: 5}, {label: 6, value: 6}, {label: 7, value: 7}, {label: 8, value: 8}, {label: 9, value: 9}, {label: 10, value: 10},
    ],
  },
  intro: {
    key: 'intro',
    label: 'Do you want an email introduction to your partner?',
    isSharedWithPartner: false,
    radio: [{label: 'Yes', value: true}, {label: 'No', value: false}],
  },

  // Fields added just for backfilled Pramp sessions
  competency: {
    key: 'competency',
    feedbackLabel: 'Demonstrating professional competency',
    isSharedWithPartner: true,
    stars: [
      'Not quite. Had trouble answering basic questions',
      'Basically. Gave mostly reasonable answers',
      'Mostly. Gave clear answers without examples',
      'Perfectly. Gave clear & concise answers using specific examples',
    ],
  },
  interpersonal_skills: {
    key: 'interpersonal_skills',
    feedbackLabel: 'Positive first impression',
    isSharedWithPartner: true,
    stars: [
      'Not quite. Got off on the wrong foot',
      'Basically. No major red flags',
      'Mostly. Would be happy to meet again',
      'Definitely. Was positively impressed',
    ],
  },
  domain_knowledge: {
    key: 'domain_knowledge',
    feedbackLabel: 'Data science domain knowledge',
    isSharedWithPartner: true,
    stars: [
      'Not ready. Couldn\'t really figure out the problem',
      'Needs some work. Decent conclusions, but not on point',
      'Really good. Some valuable insights, almost there',
      'Amazing. Cracked the problem and delivered value',
    ],
  },

  // Temporary fields while we test the robustnes of the new Exponent Practice
  av_issues: {
    key: 'av_issues',
    label: 'Did you experience any audio or video issues during today\'s session?',
    isSharedWithPartner: false,
    radio: [{label: 'Yes', value: true}, {label: 'No', value: false}],
  },
  av_issues_text: {
    key: 'av_issues_text',
    label: 'Please describe the issue you encountered and what led up to it.',
    isSharedWithPartner: false,
    written: 'The more detail, the better!',
  },
  editor_issues: {
    key: 'editor_issues',
    label: 'Did you experience any issues with the code editor during today\'s session?',
    isSharedWithPartner: false,
    radio: [{label: 'Yes', value: true}, {label: 'No', value: false}],
  },
  editor_issues_text: {
    key: 'editor_issues_text',
    label: 'Please describe the issue you encountered and what led up to it.',
    isSharedWithPartner: false,
    written: 'The more detail, the better!',
  },

  // NEW
  problem_solving_v2: {
    key: 'problem_solving_v2',
    label: 'How were your partner\'s problem solving skills?',
    feedbackLabel: 'Problem solving',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Had trouble grasping the problem',
      'Weak. Worked out a rough solution',
      'Okay. Solved the problem with hints',
      'Strong. Reached an optimal solution',
      'Very Strong. Optimal solution with minimal guidance',
    ],
  },
  coding_v2: {
    key: 'coding_v2',
    label: 'How were your partner\'s coding skills?',
    feedbackLabel: 'Coding skills',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Unfamiliar with some of the basics',
      'Weak. Struggled to get from idea to running code',
      'Okay. Could be made more readable or reusable',
      'Strong. Code is readable, reusable, and maintainable',
      'Very Strong. Would be difficult to improve code',
    ],
  },
  communication_v2: {
    key: 'communication_v2',
    label: 'How were your partner\'s communication skills?',
    feedbackLabel: 'Communication',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Hardly spoke, or was very unclear',
      'Weak. I wasn\'t able to understand some parts',
      'Okay. Mostly communicated well',
      'Strong. Communicated well throughout',
      'Very Strong. Every single bit was crystal clear',
    ],
  },
  interviewer_v2: {
    key: 'interviewer_v2',
    label: 'How did your partner perform as your interviewer?',
    feedbackLabel: 'Interviewer performance',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Did not allow for a productive session',
      'Weak. Was unprepared or unhelpful',
      'Okay. Did an adequate job',
      'Strong. Was thoughtful and prepared',
      'Very Strong. Provided excellent feedback and guidance',
    ],
  },
  product_vision: {
    key: 'product_vision',
    label: 'Were they able to articulate a clear product vision?',
    feedbackLabel: 'Product vision',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Completely lacked a product vision',
      'Weak. Struggled to define or convey a product vision',
      'Okay. Put forward reasonable ideas but lacked clarity',
      'Strong. Clearly articulated a product vision',
      'Very Strong. Communicated a compelling product vision',
    ],
  },
  prioritization: {
    key: 'prioritization',
    label: 'Did they effectively prioritize efforts when solving the problem?',
    feedbackLabel: 'Prioritization',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Failed to prioritize what to focus on',
      'Weak. Had unclear or confusing prioritization',
      'Okay. Made reasonable prioritization decisions',
      'Strong. Prioritized effectively, weighing tradeoffs',
      'Very Strong. Came up with an optimal prioritization',
    ],
  },
  business_strategy: {
    key: 'business_strategy',
    label: 'Did they demonstrate a nuanced understanding of the business and market?',
    feedbackLabel: 'Business strategy',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Failed to consider business strategy',
      'Weak. Strategy unclear',
      'Okay. Basic business sense',
      'Strong. Delivered a logical strategy',
      'Very Strong. Demonstrated strategic understanding',
    ],
  },
  data_literacy: {
    key: 'data_literacy',
    label: 'Did they identify the relevant metrics and data sources to make informed decisions?',
    feedbackLabel: 'Data literacy',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Failed to use data',
      'Weak. Struggled to use data',
      'Okay. Showed some ability to use data',
      'Strong. Used data effectively',
      'Very Strong. Extracted valuable data insights',
    ],
  },
  problem_scope: {
    key: 'problem_scope',
    label: 'Did they ask clarifying questions and identify important requirements and constraints?',
    feedbackLabel: 'Scoping the problem',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Failed to ask clarifying questions',
      'Weak. Didn\'t fully identify requirements',
      'Okay. Identified some requirements',
      'Strong. Thoughtfully identified requirements',
      'Very Strong. Clearly defined requirements',
    ],
  },
  technical_depth: {
    key: 'technical_depth',
    label: 'Did they demonstrate technical expertise by deep diving into their solution and discussing tradeoffs?',
    feedbackLabel: 'Technical depth',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Lacked the technical knowledge required',
      'Weak. Struggled to discuss technical concepts',
      'Okay. Definitely areas for improvement',
      'Strong. Articulated technical decisions clearly',
      'Very Strong. Applied deep technical knowledge',
    ],
  },
  scalability: {
    key: 'scalability',
    label: 'Did they discuss scaling strategies and address common performance bottlenecks?',
    feedbackLabel: 'Scalability',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Didn\'t consider scalability',
      'Weak. Overlooked potential scaling issues',
      'Okay. Identified some scaling strategies',
      'Strong. Demonstrated strong understanding',
      'Very Strong. Considered all scalability aspects',
    ],
  },
  competency_v2: {
    key: 'competency_v2',
    label: 'Did they demonstrate strong competency, relevant experiences, and skills necessary for the job?',
    feedbackLabel: 'Problem solving',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Failed to communicate experience and skills',
      'Weak. Unclear impression of skills',
      'Okay. Demonstrated some relevant skills',
      'Strong. Clearly communicated relevant skills',
      'Very Strong. Demonstrated strong competency',
    ],
  },
  collaboration: {
    key: 'collaboration',
    label: 'Did they demonstrate the ability to work well with others and collaborate on ideas?',
    feedbackLabel: 'Collaboration',
    isSharedWithPartner: true,
    stars: [
      'Very weak. Failed to display ability to collaborate',
      'Weak. Unclear picture of how they work with others',
      'Okay. Demonstrated some ability to collaborate',
      'Strong. Clearly communicated ability to work on a team',
      'Very Strong. Presents as an indispensable team player',
    ],
  },
};

/**
 * Types of websockets that can be set up
 */
const SocketConnectionTypes = {
  MATCHING: 'matching',
  CHAT: 'chat',
  ALGORITHMS_INTERVIEW: 'algorithms_interview',
};

/**
 * Event types that can be sent/received by a websocket client
 */
const ClientEvents = {
  CONNECTION: 'connection',
  DISCONNECT: 'disconnect',
  SETUP_COMPLETE: 'setup_complete',
  MATCH: 'match',
  NO_MATCH: 'no_match',
  MATCH_ERROR: 'match_error',
  JOIN_ROOM: 'join_room',
  CHAT_MESSAGE: 'chat_message',
  CODE_RUN: 'code_run',
  CODE_RUN_FINISHED: 'code_run_finished',
  TESTS_RUN: 'tests_run',
  TESTS_RUN_FINISHED: 'tests_run_finished',
  LANGUAGE_CHANGE: 'language_change',
  INTERVIEWER_CHANGE: 'interviewer_change',
};

const FourTimesADay = [
  // Sunday
  {day: 0, hour: 5, minute: 0}, {day: 0, hour: 11, minute: 0}, {day: 0, hour: 15, minute: 0}, {day: 0, hour: 21, minute: 0},
  // Monday
  {day: 1, hour: 5, minute: 0}, {day: 1, hour: 11, minute: 0}, {day: 1, hour: 15, minute: 0}, {day: 1, hour: 21, minute: 0},
  // Tuesday
  {day: 2, hour: 5, minute: 0}, {day: 2, hour: 11, minute: 0}, {day: 2, hour: 15, minute: 0}, {day: 2, hour: 21, minute: 0},
  // Wednesday
  {day: 3, hour: 5, minute: 0}, {day: 3, hour: 11, minute: 0}, {day: 3, hour: 15, minute: 0}, {day: 3, hour: 21, minute: 0},
  // Thursday
  {day: 4, hour: 5, minute: 0}, {day: 4, hour: 11, minute: 0}, {day: 4, hour: 15, minute: 0}, {day: 4, hour: 21, minute: 0},
  // Friday
  {day: 5, hour: 5, minute: 0}, {day: 5, hour: 11, minute: 0}, {day: 5, hour: 15, minute: 0}, {day: 5, hour: 21, minute: 0},
  // Saturday
  {day: 6, hour: 5, minute: 0}, {day: 6, hour: 11, minute: 0}, {day: 6, hour: 15, minute: 0}, {day: 6, hour: 21, minute: 0},
];

const everyTwoHours = [
  // Sunday
  {day: 0, hour: 1, minute: 0}, {day: 0, hour: 3, minute: 0}, {day: 0, hour: 5, minute: 0}, {day: 0, hour: 7, minute: 0}, {day: 0, hour: 9, minute: 0}, {day: 0, hour: 11, minute: 0}, {day: 0, hour: 13, minute: 0}, {day: 0, hour: 15, minute: 0}, {day: 0, hour: 17, minute: 0}, {day: 0, hour: 19, minute: 0}, {day: 0, hour: 21, minute: 0}, {day: 0, hour: 23, minute: 0},
  // Monday
  {day: 1, hour: 1, minute: 0}, {day: 1, hour: 3, minute: 0}, {day: 1, hour: 5, minute: 0}, {day: 1, hour: 7, minute: 0}, {day: 1, hour: 9, minute: 0}, {day: 1, hour: 11, minute: 0}, {day: 1, hour: 13, minute: 0}, {day: 1, hour: 15, minute: 0}, {day: 1, hour: 17, minute: 0}, {day: 1, hour: 19, minute: 0}, {day: 1, hour: 21, minute: 0}, {day: 1, hour: 23, minute: 0},
  // Tuesday
  {day: 2, hour: 1, minute: 0}, {day: 2, hour: 3, minute: 0}, {day: 2, hour: 5, minute: 0}, {day: 2, hour: 7, minute: 0}, {day: 2, hour: 9, minute: 0}, {day: 2, hour: 11, minute: 0}, {day: 2, hour: 13, minute: 0}, {day: 2, hour: 15, minute: 0}, {day: 2, hour: 17, minute: 0}, {day: 2, hour: 19, minute: 0}, {day: 2, hour: 21, minute: 0}, {day: 2, hour: 23, minute: 0},
  // Wednesday
  {day: 3, hour: 1, minute: 0}, {day: 3, hour: 3, minute: 0}, {day: 3, hour: 5, minute: 0}, {day: 3, hour: 7, minute: 0}, {day: 3, hour: 9, minute: 0}, {day: 3, hour: 11, minute: 0}, {day: 3, hour: 13, minute: 0}, {day: 3, hour: 15, minute: 0}, {day: 3, hour: 17, minute: 0}, {day: 3, hour: 19, minute: 0}, {day: 3, hour: 21, minute: 0}, {day: 3, hour: 23, minute: 0},
  // Thursday
  {day: 4, hour: 1, minute: 0}, {day: 4, hour: 3, minute: 0}, {day: 4, hour: 5, minute: 0}, {day: 4, hour: 7, minute: 0}, {day: 4, hour: 9, minute: 0}, {day: 4, hour: 11, minute: 0}, {day: 4, hour: 13, minute: 0}, {day: 4, hour: 15, minute: 0}, {day: 4, hour: 17, minute: 0}, {day: 4, hour: 19, minute: 0}, {day: 4, hour: 21, minute: 0}, {day: 4, hour: 23, minute: 0},
  // Friday
  {day: 5, hour: 1, minute: 0}, {day: 5, hour: 3, minute: 0}, {day: 5, hour: 5, minute: 0}, {day: 5, hour: 7, minute: 0}, {day: 5, hour: 9, minute: 0}, {day: 5, hour: 11, minute: 0}, {day: 5, hour: 13, minute: 0}, {day: 5, hour: 15, minute: 0}, {day: 5, hour: 17, minute: 0}, {day: 5, hour: 19, minute: 0}, {day: 5, hour: 21, minute: 0}, {day: 5, hour: 23, minute: 0},
  // Saturday
  {day: 6, hour: 1, minute: 0}, {day: 6, hour: 3, minute: 0}, {day: 6, hour: 5, minute: 0}, {day: 6, hour: 7, minute: 0}, {day: 6, hour: 9, minute: 0}, {day: 6, hour: 11, minute: 0}, {day: 6, hour: 13, minute: 0}, {day: 6, hour: 15, minute: 0}, {day: 6, hour: 17, minute: 0}, {day: 6, hour: 19, minute: 0}, {day: 6, hour: 21, minute: 0}, {day: 6, hour: 23, minute: 0},
];

const DuetSchedules = {
  PM: FourTimesADay,
  'System-Design': FourTimesADay,
  Behavioral: FourTimesADay,
  Algorithms: everyTwoHours,
};

module.exports = {
  TIMEZONE,
  MAX_REJOIN_TIME,
  MAX_FEEDBACK_TIME,
  LEADING_JOIN_TIME,
  TRAILING_MATCH_TIMES,
  WINDOW_START_TIME,
  FREE_DUET_SESSION_TTL,
  SCREENSHARE_TRACK,
  DUET_SESSIONS_PER_PERIOD,
  CreditRefundReasons,
  InterviewTypesArray,
  InterviewTypes,
  RequestStatus,
  PartnerIntroStatus,
  FeedbackFields,
  SocketConnectionTypes,
  ClientEvents,
  DuetSchedules,
};

import React, {useEffect} from 'react';
import {GOOGLE_WEB_CLIENT_ID, BASE_URL} from '../constants/config';

const GoogleOneTap = ({show}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      id="g_id_onload"
      data-client_id={GOOGLE_WEB_CLIENT_ID}
      data-login_uri={`${BASE_URL}/login/google/oneClick`}
      data-auto_prompt={show}
    />
  );
};

export default GoogleOneTap;

import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

const CollapserThing = ({visible, children}) => {
  const content = useRef(null);
  const [height, setHeight] = useState(visible ? 'auto' : 0);
  useEffect(() => {
    setHeight(content && visible ? content.current.scrollHeight : 0);
  }, [visible]);

  return <div className="transition-all duration-300 ease-in-out overflow-hidden" style={{height}} ref={content}>{children}</div>;
};

CollapserThing.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default CollapserThing;

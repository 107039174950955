import React from 'react';
import _ from 'lodash';

import CoachFeatures from '../../assets/images/features_coach.png';
import CourseFeatures from '../../assets/images/features_course.png';
import QuestionFeatures from '../../assets/images/features_question.png';

const Content = {
  DEFAULT: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Land your dream tech job',
    landingSub: 'Join the interview prep platform and community that thousands of people are using.',
    landingCtaText: 'View courses',
    landingCtaUrl: '/courses',
    productOverview: {
      lead: 'Our features',
      heading: 'An all-in-one platform for interview prep',
      productsList: {
        product1: {
          name: 'Courses that get you the job',
          description: (
            <p>
              Our courses help you
              <span className="text-indigo"> get the job you want </span>
              with real interview examples and advice from hiring managers
              at Google, Facebook, and more.
            </p>
          ),
          image: CourseFeatures.src,
          ctaUrl: '/courses',
          ctaText: 'View courses',
        },
        product2: {
          name: 'Expert coaches from top companies',
          description: (
            <p>
              Get matched with the perfect mentor to help you prepare for the interview process
              recommended coaches or browse by role and company experience.
            </p>
          ),
          image: CoachFeatures.src,
          ctaUrl: '/coaching',
          ctaText: 'Find your coach',
        },
        product3: {
          name: 'Real interview questions',
          description: (
            <p>
              Discover the interview questions companies actually ask and watch how
              experts answer them in over
              <span className="text-indigo"> 100+ live video answers</span>
              .
            </p>
          ),
          image: QuestionFeatures.src,
          ctaUrl: '/questions',
          ctaText: 'Practice questions',
        },
      },
    },
  },
  NEW_LANDING_PAGE: {
    pageTitle: 'Premium career advancement tools for PM, Engineering, and More',
    landingHeader: 'Everything you need to ace your interview',
    landingSub: 'Join an expansive community for career growth and interview prep.',
    landingCtaText: 'View courses',
    landingCtaUrl: '/courses',
    productOverview: {
      lead: 'Our features',
      heading: 'The all-in-one platform for interview prep',
      productsList: {
        product1: {
          name: 'Courses that help you get the job',
          description: (
            <p>
              Our courses help you
              <span className="text-indigo"> prepare for interviews </span>
              and
              <span className="text-indigo"> excel at your job</span>
              .
              Get advice from technical leads and product owners at hundreds of tech companies.
              Show off your expert knowledge with a certificate of course completion.
            </p>
          ),
          image: CourseFeatures.src,
          ctaUrl: '/courses',
          ctaText: 'View courses',
        },
        product2: {
          name: 'Expert coaches from top companies',
          description: (
            <p>
              Get matched with the perfect mentor to help you advance your career
              at a new or existing role.
            </p>
          ),
          image: CoachFeatures.src,
          ctaUrl: '/coaching',
          ctaText: 'Find your coach',
        },
        product3: {
          name: 'Real interview questions',
          description: (
            <p>
              Switching companies? Discover the interview questions companies actually ask
              and watch how experts answer them in over
              <span className="text-indigo"> 100+ live video answers</span>
              .
            </p>
          ),
          image: QuestionFeatures.src,
          ctaUrl: '/questions',
          ctaText: 'Practice questions',
        },
      },
    },
  },
  PM: {
    pageTitle: 'PM Interview Prep',
    landingHeader: 'Ace your PM interview',
    landingSub: 'Land your dream PM job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/pm',
    productOverview: {
      heading: 'The complete platform land your dream PM job',
    },
  },
  PMM: {
    pageTitle: 'PMM Interview Prep',
    landingHeader: 'Ace your product marketing management interview',
    landingSub: 'Land your dream PMM job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/pmm',
    productOverview: {
      heading: 'The complete platform to land your dream product marketing job',
    },
  },
  SWE: {
    pageTitle: 'SWE Interview Prep',
    landingHeader: 'Ace your software engineering interview',
    landingSub: 'Land your dream SWE job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/software-engineering',
    productOverview: {
      heading: 'The complete platform to land your dream software engineering job',
    },
  },
  TPM: {
    pageTitle: 'TPM Interview Prep',
    landingHeader: 'Ace your TPM interview',
    landingSub: 'Land your dream TPM job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/tpm',
    productOverview: {
      heading: 'The complete platform to land your dream TPM job',
    },
  },
  SYSTEM_DESIGN: {
    pageTitle: 'System Design Interview Prep',
    landingHeader: 'Ace your system design interview',
    landingSub: 'Land your dream tech job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/system-design-interviews',
    productOverview: {
      heading: 'The complete platform to crack your system design interviews',
    },
  },
  PROD_MGMT: {
    pageTitle: 'Product Management Interview Prep',
    landingHeader: 'Ace your product management interview',
    landingSub: 'Land your dream product management job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/pm',
    productOverview: {
      heading: 'The complete platform to land your dream product management job',
    },
  },
  PROD_STRATEGY: {
    pageTitle: 'Product Strategy Interview Prep',
    landingHeader: 'Ace your product strategy interview',
    landingSub: 'Land your dream product strategy job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/pm',
    productOverview: {
      heading: 'The complete platform to land your dream product strategy job',
    },
  },
  BUSINESS_OPS: {
    pageTitle: 'Business Operations Interview Prep',
    landingHeader: 'Ace your business operations interview',
    landingSub: 'Land your dream business operations job at LinkedIn, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/product-strategy',
    productOverview: {
      heading: 'The complete platform to land your dream business operations job',
    },
  },
  PRODUCT_OPS: {
    pageTitle: 'Product Operations Interview Prep',
    landingHeader: 'Ace your product operations interview',
    landingSub: 'Land your dream product operations job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/pm',
    productOverview: {
      heading: 'The complete platform to land your dream product operations job',
    },
  },
  SOLUTIONS_ARCHITECT: {
    pageTitle: 'Solutions Architect Interview Prep',
    landingHeader: 'Ace your solutions architect interview',
    landingSub: 'Land your dream solutions architect job at Amazon (AWS), Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/solution-architect-interview',
    productOverview: {
      heading: 'The complete platform to land your dream solutions architect job',
    },
  },
  CLOUD_ARCHITECT: {
    pageTitle: 'Cloud Architect Interview Prep',
    landingHeader: 'Ace your cloud architect interview',
    landingSub: 'Land your dream cloud architect job at Google Cloud, Salesforce and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/solution-architect-interview',
    productOverview: {
      heading: 'The complete platform to land your dream cloud architect job',
    },
  },
  SOLUTIONS_ENGINEER: {
    pageTitle: 'Solutions Engineer Interview Prep',
    landingHeader: 'Ace your solutions engineer interview',
    landingSub: 'Land your dream solutions engineer job at Meta, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/solution-architect-interview',
    productOverview: {
      heading: 'The complete platform to land your dream solutions engineer job',
    },
  },
  SECURITY_ENGINEER: {
    pageTitle: 'Security Engineer Interview Prep',
    landingHeader: 'Ace your security engineer interview',
    landingSub: 'Land your dream security engineer job at Meta, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/software-engineering',
    productOverview: {
      heading: 'The complete platform to land your dream security engineer job',
    },
  },
  PRODUCT_DESIGNER: {
    pageTitle: 'Product Designer Interview Prep',
    landingHeader: 'Ace your product designer interview',
    landingSub: 'Land your dream product designer job at Meta, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/product-designer-interview',
    productOverview: {
      heading: 'The complete platform to land your dream product designer job',
    },
  },
  INTERACTION_DESIGNER: {
    pageTitle: 'Interaction Designer Interview Prep',
    landingHeader: 'Ace your interaction designer interview',
    landingSub: 'Land your dream interaction designer job at Google, startups, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/product-designer-interview',
    productOverview: {
      heading: 'The complete platform to land your dream interaction designer job',
    },
  },
  VISUAL_DESIGNER: {
    pageTitle: 'Visual Designer Interview Prep',
    landingHeader: 'Ace your interaction designer interview',
    landingSub: 'Land your dream visual designer job at Google, startups, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/product-designer-interview',
    productOverview: {
      heading: 'The complete platform to land your dream visual designer job',
    },
  },
  UX_DESIGNER: {
    pageTitle: 'UX Designer Interview Prep',
    landingHeader: 'Ace your UX designer interview',
    landingSub: 'Land your dream ux designer job at Google, startups, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/product-designer-interview',
    productOverview: {
      heading: 'The complete platform to land your dream UX designer job',
    },
  },
  PRODUCT_ANALYTICS: {
    pageTitle: 'Product Analytics Interview Prep',
    landingHeader: 'Ace your product analytics interview',
    landingSub: 'Land your dream product analytics job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/analytical',
    productOverview: {
      heading: 'The complete platform to land your dream product analytics job',
    },
  },
  GMM: {
    pageTitle: 'Growth Marketing Manager Interview Prep',
    landingHeader: 'Ace your growth marketing manager interview',
    landingSub: 'Land your dream growth marketing manager job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/pmm',
    productOverview: {
      heading: 'The complete platform to land your dream growth marketing manager job',
    },
  },
  AMZ_LEADERSHIP: {
    pageTitle: 'Amazon Leadership Principles Interview Prep',
    landingHeader: 'Ace your Amazon leadership principles interview',
    landingSub: 'Land your dream job at Amazon',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/amazon-interview',
    productOverview: {
      heading: 'The complete platform to land your dream Amazon job',
    },
  },
  SDE: {
    pageTitle: 'Software Development Engineer Interview Prep',
    landingHeader: 'Ace your software development engineer interview',
    landingSub: 'Land your dream software development engineer job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/software-engineering',
    productOverview: {
      heading: 'The complete platform to land your dream SDE job',
    },
  },
  EM: {
    pageTitle: 'Engineering Manager Interview Prep',
    landingHeader: 'Ace your engineering manager interview',
    landingSub: 'Land your dream engineering manager job at Facebook, Google, and more',
    landingCtaText: 'View course',
    landingCtaUrl: '/courses/engineering-management',
    productOverview: {
      heading: 'The complete platform to land your dream engineering management job',
    },
  },

  // Discount landing pages
  WELCOME: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
  },
  PRAMP: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive coaching discount for Pramp members',
    landingSub: 'Select a coach to get started',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/coaching?role=swe',
  },
  DISCOUNT10: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_MONTHLY',
    promoTimePeriod: ' / month',
    promoDiscountAmount: '20% Off',
    promoName: 'Monthly',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  PRODUCTHUNT: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Product Hunters',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
  },
  MARIOGERARD: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for The TPM Blog members',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    // actualExpiration: '24 March 2021 11:03:00 PDT',
  },
  THEINTERVIEWSAGE: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for The Interview Sage members',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
  },
  OFF10: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 10% off our membership',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: ' / year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
  TENOFFZ: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 10% off our membership',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: ' / year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
  OFFTEN10: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 10% off our membership',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: ' / year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
  TWENTYOFF: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 20% off our membership',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: ' / year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
  PMTODAY: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 20% off our PM membership',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
  TWENTY5OFF: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 25% off our membership',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_MONTHLY',
    promoTimePeriod: ' / month',
    promoDiscountAmount: '25% Off',
    promoName: 'Monthly',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
  OFFTHIRTY: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 30% off our membership',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '30% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
  MEMORIALDAY: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 20% off our membership',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  PRAMP20: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Pramp: get 20% off expert interview prep',
    landingSub: 'Nail your software engineering interview. Practice real-world questions and get the inside scoop on interviews.',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: ' / year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  COACHTWENTY: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 20% off coaching packages',
    landingSub: 'Select a coach to get started',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/coaching',
    noindex: true,
  },
  COACH15: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Save up to $150 on coaching packages',
    landingSub: 'Choose an interview prep coach to get started',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/coaching',
    noindex: true,
  },
  CYBERMONDAY: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get our exclusive Cyber Monday discount!',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  BPM: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Becoming Product Managers community',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
  },
  COMEBACK: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Rejoin Exponent today with our special offer',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  NOVA: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for the Nova community',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
  },
  PMWHILEBLACK: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for PM While Black users',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
  },
  PRODUCTBUDS: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Product Buds members',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
  },
  LTCWM: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Learn To Code With Me users',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
  },
  REFERRALWIN: {
    pageTitle: 'You Got 10% Off Exponent!',
    landingHeader: "You've been referred!",
    landingSub: "Here's 10% off! Offer ends soon.",
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  APMMAP: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for APM Map members',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
  },
  HIRED: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Hired Users: Get 20% off our membership',
    landingSub: 'Use code HIRED20 to save 20% on our system design interview courses and more.',
    landingCtaText: null,
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/ year',
    promoDiscountAmount: '20% Off',
    promoPriceString: '$119',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,

    // Special case for Hired: Show monthly/annual toggle
    showPlanToggle: true,
    monthlyPromoProduct: 'MEMBERSHIP_MONTHLY',
    monthlyPromoTimePeriod: '/ month',
    monthlyPromoPriceString: '$63',
    monthlyPromoName: 'Monthly',
    monthlyPromoCtaText: 'Start now',
  },
  GSBALUM: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Stanford GSB Alumni',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  WHARTONALUM: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Wharton Alumni',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  WEB3PMS: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Web3PMs',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  CORNELL: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Cornell Students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  LINKEDIN: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for LinkedIn Employees',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  APMS: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for aspiring APMs',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  MIDS10: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for MIDS students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  MSBA10: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for MSBA students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  BOOTH: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Booth students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  KELLOGG: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Kellogg students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  BYTEBYBYTE: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Byte by Byte users',
    landingSub: 'System design and software engineering courses included. Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  RAMTGRM: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  IPM: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for the Intentional PM Community',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  PATHRISE: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for the Pathrise Community',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  TUCK: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Tuck MBA Students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  PMTRANSFERCLUB: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Google PM Transfers',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  FELIXJR: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for the PM Mastermind Community',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  BONOBO: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Bonobo subscribers',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  RITHMCOURSES: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Rithm School students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  ACCENT: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  RITHMCOACHING: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Rithm School students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  APMLIST20: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for APMList Users',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: ' / year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  PRODUCTGYM: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Product Gym Members',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  CANDOR: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for Candor users',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  ANGEL: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for AngelList users',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  HECTECH: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for HEC Tech students',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  DCP: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for DCP users ',
    landingSub: 'System design and coding courses included. Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  JOMA: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 10% discount for Joma Tech Fans',
    landingSub: 'System design and coding courses included. Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  THECOMMONS: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 20% discount for The Commons Users',
    landingSub: 'Limited offer ends today!',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 2,
    noindex: true,
  },
  CYBER30: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 30% off expert interview prep.',
    landingSub: 'Limited offer ends soon.',
    landingCtaText: 'Upgrade your plan',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '30% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    actualExpiration: '2023-11-28',
    bannerPrefix: 'Ends soon:',
    noindex: true,
  },
  CYBER20: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Get 20% off expert interview coaching',
    landingSub: 'Limited offer ends soon.',
    landingCtaText: 'Claim this offer',
    landingCtaUrl: '/coaching',
    promoDiscountAmount: '20% Off',
    actualExpiration: '2023-11-28',
    bannerPrefix: 'Ends soon:',
    noindex: true,
  },
  SINGLESPROUT23: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'SingleSprout + Exponent special offer',
    landingSub: 'SingleSprout candidates get a free Exponent membership. Try all our courses, practice tools, and more.',
    landingCtaText: 'Get started now ->',
    landingCtaUrl: '/invite/singlesprout',
    showDiscount: false,
    noindex: true,
  },
  SINGLESPROUT: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'SingleSprout + Exponent special offer',
    landingSub: 'SingleSprout candidates get a free Exponent membership. Try all our courses, practice tools, and more.',
    landingCtaText: 'Get started now ->',
    landingCtaUrl: '/invite/singlesprout',
    showDiscount: false,
    noindex: true,
  },
  ADVANTIS: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Advantis + Exponent special offer',
    landingSub: 'Advantis candidates get a free Exponent membership. Try all our courses, practice tools, and more.',
    landingCtaText: 'Get started now ->',
    landingCtaUrl: '/invite/advantis',
    showDiscount: false,
    noindex: true,
  },
  APMLIST: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Exclusive 40% off for APM List Users',
    landingSub: 'Limited time offer - expires July | Monthly memberships only',
    landingCtaText: null,
    landingCtaUrl: null,
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_MONTHLY',
    promoTimePeriod: ' / month',
    promoDiscountAmount: '38% Off',
    promoName: 'Monthly',
    promoCtaText: 'Start now',
    actualExpiration: '2023-07-31',
    noindex: true,
    hideBanner: true,
  },
  UDACITY: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Unlock free interview prep with Udacity',
    landingSub: 'Your Udacity membership gives you free access to Exponent\'s interview prep courses, peer mock interviews, and more.',
    landingCtaText: 'Activate my account ->',
    landingCtaUrl: '/invite/udacity',
    showDiscount: false,
    noindex: true,
    leftHeroComponent: <img className="self-center flex ml-0.5 w-40" src="/images/partners/udacity.png" alt="Udacity" />,
  },
  UDACITY_REACTIVATION: {
    pageTitle: 'Interview Prep for PM, Engineering, and More',
    landingHeader: 'Unlock free interview prep with Udacity',
    landingSub: 'Your Udacity membership gives you free access to Exponent\'s interview prep courses, peer mock interviews, and more.',
    landingCtaText: 'Activate my account ->',
    landingCtaUrl: '/invite/udacity-reactivation',
    showDiscount: false,
    noindex: true,
    leftHeroComponent: <img className="self-center flex ml-0.5 w-40" src="/images/partners/udacity.png" alt="Udacity" />,
  },
  UCDAVIS: {
    pageTitle: 'Exponent at UC Davis',
    landingHeader: 'Get Exponent as a UC Davis GSM student',
    landingSub: 'As a UC Davis GSM student, you get free access to Exponent\'s interview prep courses, peer mock interviews, and more.',
    landingCtaText: 'Activate my account ->',
    landingCtaUrl: '/invite/ucdavis',
    showDiscount: false,
    noindex: true,
  },
  SCALEJOBS: {
    pageTitle: 'Exponent for Scale.jobs Members',
    landingHeader: 'Scale.jobs + Exponent special offer',
    landingSub: 'Exclusive 10% discount for our interview prep courses and membership.',
    landingCtaText: 'Claim offer',
    landingCtaUrl: '/upgrade',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '10% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
  NEXTPLAY: {
    pageTitle: 'Exponent for Scale.jobs Members',
    landingCtaText: 'Claim offer',
    showDiscount: true,
    promoProduct: 'MEMBERSHIP_ANNUAL',
    promoTimePeriod: '/year',
    promoDiscountAmount: '20% Off',
    promoName: 'Annual',
    promoCtaText: 'Start now',
    userExpirationDays: 1,
    noindex: true,
  },
};

function getContent(id) {
  return _.merge({}, Content.DEFAULT, Content[id ? id.toUpperCase() : id]);
}

export default getContent;

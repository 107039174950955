import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getPromotion = () => cookies.get('promo_code');

export const getTimerExpiration = (content, promo) => {
  const nowTimestamp = new Date();
  const localExpiration = localStorage.getItem(`user_expiration_${promo}`);
  if (localExpiration) {
    const localTimestamp = Date.parse(localExpiration);
    if (nowTimestamp < localTimestamp) {
      return localTimestamp;
    }
    if (content.userExpirationDays) {
      const userExpirationMs = 1000 * 60 * 60 * 24 * content.userExpirationDays;
      nowTimestamp.setMilliseconds(nowTimestamp.getMilliseconds() + userExpirationMs);
      localStorage.setItem(`user_expiration_${promo}`, nowTimestamp);
      return nowTimestamp;
    }
    return 'expired';
  }
  if (content.actualExpiration) {
    const actualTimestamp = Date.parse(content.actualExpiration);
    localStorage.setItem(`user_expiration_${promo}`, content.actualExpiration);
    return nowTimestamp < actualTimestamp ? actualTimestamp : 'expired';
  }
  if (content.userExpirationDays) {
    const userExpirationMs = 1000 * 60 * 60 * 24 * content.userExpirationDays;
    nowTimestamp.setMilliseconds(nowTimestamp.getMilliseconds() + userExpirationMs);
    localStorage.setItem(`user_expiration_${promo}`, nowTimestamp);
    return nowTimestamp;
  }
  return null;
};

export const getDismissed = (bannerId) => {
  if (typeof localStorage !== 'undefined') {
    return Boolean(JSON.parse(localStorage.getItem(`dismiss_banner_${bannerId}`)));
  }
  return false;
};

export const setDismissed = (bannerId) => {
  if (typeof window.localStorage !== 'undefined') {
    localStorage.setItem(`dismiss_banner_${bannerId}`, true);
  }
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {signUpForEmail} from '../api/api';
import Button, {ButtonSizes} from './ButtonNew';

export default class EmailSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      submitted: false,
    };
  }

  handleChange = (e) => {
    this.setState({email: e.target.value});
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {email} = this.state;
    const {track, src} = this.props;
    this.setState({submitted: true});
    await signUpForEmail(email, track, src);
  };

  render() {
    const {email, submitted} = this.state;
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`${this.props.parent}__form`}
      >
        <input
          onChange={this.handleChange}
          type="email"
          name="EMAIL"
          value={email}
          disabled={submitted}
          className={`${this.props.parent}__input`}
          placeholder="Your email"
          required
        />
        <Button
          className={`${this.props.parent}__submit`}
          disabled={submitted}
          size={ButtonSizes.LARGE}
          type="submit"
        >
          {submitted ? 'Done!' : `${this.props.signupText}`}
        </Button>
      </form>
    );
  }
}

EmailSignUp.propTypes = {
  parent: PropTypes.string.isRequired,
  track: PropTypes.string,
  src: PropTypes.string,
  signupText: PropTypes.string,
};

EmailSignUp.defaultProps = {
  track: null,
  src: null,
  signupText: 'Sign up',
};

import React, {useEffect} from 'react';

import {AppConsumer} from './AppContext';
import {tracker, trackConversion, updateUserAnalytics} from '../util/UserAnalytics';

const AnalyticsProviderComponent = ({currentUser, hasSubscription, children}) => {
  useEffect(() => {
    updateUserAnalytics(currentUser, hasSubscription);
  }, [currentUser, hasSubscription]);

  // Initialize the tracker with hasSubscription
  const trackerWithContext = tracker({hasSubscription});

  return (
    <AnalyticsContext.Provider value={{tracker: trackerWithContext, trackConversion}}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// One of the few places where we need to rely on this pattern rather than using
// useAppContext(), in order to avoid a circular dependency.
export const AnalyticsProvider = ({children}) => (
  <AppConsumer>
    {({currentUser, hasSubscription}) => (
      <AnalyticsProviderComponent
        currentUser={currentUser}
        hasSubscription={hasSubscription}
      >
        {children}
      </AnalyticsProviderComponent>
    )}
  </AppConsumer>
);

export const AnalyticsContext = React.createContext({});
export const AnalyticsConsumer = AnalyticsContext.Consumer;

/**
 * This file provides helpers for adding structured data
 * to search results. Review Google's guidelines before
 * adding to pages since incorrect use can penalize us.
 */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Head from 'next/head';
import {isAbsoluteURL} from '../util';
import {BASE_URL} from '../constants/config';

export const Website = () => (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: 'Exponent',
        publisher: {
          '@type': 'Organization',
          name: 'Exponent',
          url: 'https://www.tryexponent.com',
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.tryexponent.com/favicon.png',
          },
        },
        url: 'https://www.tryexponent.com',
        mainEntityOfPage: 'https://www.tryexponent.com',
        description: 'Interview prep for product management, software engineering, and other tech roles.',
      })}
    </script>
  </Head>
);

export const Breadcrumb = ({items = []}) => (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items.map((item, i) => ({
          '@type': 'ListItem',
          position: i + 1,
          name: item.name,
          item: isAbsoluteURL(item.url) ? item.url : BASE_URL + item.url,
        })),
      })}
    </script>
  </Head>
);

export const Article = ({
  headline,
  image,
  datePublished, // ISO 8601
  dateModified, // ISO 8601
}) => (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline,
        image,
        datePublished,
        dateModified,
      })}
    </script>
  </Head>
);

export const Course = ({name, description}) => (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Course',
        name,
        description,
        provider: {
          '@type': 'Organization',
          name: 'Exponent',
          sameAs: 'https://www.tryexponent.com',
        },
      })}
    </script>
  </Head>
);

// Google wants at least 3 results: https://developers.google.com/search/docs/advanced/structured-data/course#technical-guidelines
export const CourseCarousel = ({courses = []}) => courses.length >= 3 && (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        numberOfItems: courses.length,
        itemListElement: courses.map((course, i) => ({
          '@type': 'ListItem',
          position: i + 1,
          // Even if CourseCarousel is presented within a track, use
          // the url of the standalone course, otherwise it will 404
          url: `/courses/${course.slug}`,
        })),
      })}
    </script>
  </Head>
);

export const AggregateRating = ({
  ratingValue,
  ratingCount,
  worstRating,
  bestRating,
}) => (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'AggregateRating',
        ratingValue,
        ratingCount,
        bestRating,
        worstRating,
      })}
    </script>
  </Head>
);

export const Product = ({
  name,
  description,
  aggregateRating,
}) => (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Product',
        name,
        description,
        aggregateRating: aggregateRating ? ({
          '@context': 'https://schema.org',
          '@type': 'AggregateRating',
          ratingValue: aggregateRating.ratingValue,
          ratingCount: aggregateRating.ratingCount,
          bestRating: aggregateRating.bestRating,
          worstRating: aggregateRating.worstRating,
        }) : undefined,
      })}
    </script>
  </Head>
);

export const FAQPage = ({items = []}) => items.length >= 0 && (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: items.map((item) => ({
          '@type': 'Question',
          name: ReactDOMServer.renderToStaticMarkup(item.question),
          acceptedAnswer: {
            '@type': 'Answer',
            text: ReactDOMServer.renderToStaticMarkup(item.answer),
          },
        })),
      })}
    </script>
  </Head>
);

export const QAPage = ({
  question,
  questionText,
  acceptedAnswer,
  answers,
  upvoteCount,
  dateCreated,
}) => (
  <Head>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'QAPage',
        mainEntity: {
          '@type': 'Question',
          name: question,
          text: questionText,
          answerCount: answers ? answers.length : 0,
          upvoteCount,
          dateCreated,
          acceptedAnswer: acceptedAnswer ? {
            '@type': 'Answer',
            text: acceptedAnswer ? acceptedAnswer.text : undefined,
            upvoteCount: acceptedAnswer ? acceptedAnswer.upvotes : undefined,
            url: acceptedAnswer ? acceptedAnswer.url : undefined,
          } : undefined,
          suggestedAnswer: (answers || []).map((answer) => ({
            '@type': 'Answer',
            text: answer ? answer.text : undefined,
            upvoteCount: answer ? answer.upvotes : undefined,
            url: answer ? answer.url : undefined,
          })),
        },
      })}
    </script>
  </Head>
);

import React from 'react';
import NextLink from 'next/link';
import {isExternalURL} from '../util';

const Link = ({
  href,
  forceAnchor,
  children,
  prefetch = false,
  shallow = false,
  ...props
}) => {
  if (isExternalURL(href)) return <a href={href} rel="noopener noreferrer" target="_blank" {...props}>{children}</a>;

  if (forceAnchor) return <a href={href} {...props}>{children}</a>;

  return (
    <NextLink href={href} prefetch={prefetch} shallow={shallow}>
      <a {...props}>{children}</a>
    </NextLink>
  );
};

export default Link;

import React from 'react';

import Link from './Link';

const NavigationDropdownItem = ({item, onClick}) => (
  <Link href={item.link} onClick={onClick} forceAnchor={item.forceAnchor}>
    <li className="flex py-2 pl-2 pr-8 rounded-lg whitespace-normal transition ease-in-out duration-150 hover:bg-gray-50" key={item.title}>
      {item.icon && <item.icon className="h-6 w-6 mr-3 flex-shrink-0 text-indigo" />}
      <div>
        <div className="flex items-center">
          <span className="text-base text-gray-800 whitespace-nowrap sm:font-medium">{item.title}</span>
          {item.badge && (
            <div className="rounded-full bg-indigo-100 px-2 py-0.5 font-medium text-xs text-indigo-600 whitespace-nowrap ml-2">
              {item.badge}
            </div>
          )}
        </div>
        {item.description && <span className="block text-sm font-normal text-gray-600">{item.description}</span>}
      </div>
    </li>
  </Link>
);

export default NavigationDropdownItem;

import React from 'react';
import _ from 'lodash';

const truncateString = (str, maxLength = null, reverse = false) => {
  if (!maxLength || str.length <= maxLength) return str;

  if (!reverse) {
    const spaceIndex = str.indexOf(' ', maxLength); // Get the index of space after maxLength
    const truncated = spaceIndex === -1
      ? str.substring(0, maxLength)
      : str.substring(0, spaceIndex);
    return `${truncated}...`;
  }

  const spaceIndex = str.lastIndexOf(' ', -1 * maxLength); // Get the index of space after maxLength
  const truncated = spaceIndex === -1
    ? str.substring(str.length - maxLength)
    : str.substring(spaceIndex);
  return `...${truncated}`;
};

const Highlighted = ({text = null, match, maxCharPadding = null}) => {
  if (!text) return null;
  if (!match) return maxCharPadding ? text.substring(0, maxCharPadding * 2) : text;

  // Fuse.js returns a lot of fake match indices for some reason. Only take longest.
  // See: https://github.com/krisk/Fuse/issues/611
  const longestMatch = _.maxBy(match.indices, ([start, end]) => (end - start));

  const beforeHighlight = match.value.slice(0, longestMatch[0]);
  const highlight = match.value.slice(longestMatch[0], longestMatch[1] + 1);
  const afterHighlight = match.value.slice(longestMatch[1] + 1);

  return (
    <span>
      {truncateString(beforeHighlight, maxCharPadding, true)}
      <span className="bg-yellow-200">{highlight}</span>
      {truncateString(afterHighlight, maxCharPadding)}
    </span>
  );
};

export default Highlighted;

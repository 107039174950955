import React from 'react';
import cx from 'classnames';

import Link from './Link';
import {StarIcon} from './svg';
import {formatLargeNum} from '../util';

export const KarmaPillSizes = {
  REGULAR: 'regular',
  LARGE: 'large',
  XLARGE: 'xlarge',
};

const KarmaPill = ({
  score,
  size = KarmaPillSizes.REGULAR,
  outline = false,
  verbose = false,
  onClick,
  className,
}) => (
  <Link
    className={cx('flex items-center rounded-full',
      {
        'py-1 pl-2 pr-3': size === KarmaPillSizes.XLARGE,
        'py-0.5 pl-1.5 pr-2': size !== KarmaPillSizes.XLARGE,
        'bg-gray-50': !outline,
        'bg-white border border-gray-200': outline,
      },
      className)}
    href="/karma"
    title={`${score ? score.toLocaleString() : '0'} karma`}
    onClick={onClick}
  >
    <StarIcon className={cx('text-yellow-400',
      {
        'h-5 w-5 mr-1': size === KarmaPillSizes.XLARGE,
        'h-4 w-4 mr-0.5': size === KarmaPillSizes.LARGE,
        'h-3.5 w-3.5 mr-0.5': size === KarmaPillSizes.REGULAR,
      })}
    />
    <span className={cx('font-medium text-gray-400',
      {
        'text-base font-semibold': size === KarmaPillSizes.XLARGE,
        'text-sm': size === KarmaPillSizes.LARGE,
        'text-xs': size === KarmaPillSizes.REGULAR,
      })}
    >
      {verbose && '+'}
      {formatLargeNum(score)}
    </span>
  </Link>
);

export default KarmaPill;

import React from 'react';
import cx from 'classnames';
import _ from 'lodash';

import Button, {ButtonTypes} from './ButtonNew';
import FadeOut from './FadeOut';

export const Chiclet = ({
  name, href, selected, onClick = _.noop,
}) => (
  <Button
    key={name}
    buttonType={ButtonTypes.SECONDARY}
    onClick={() => onClick(name)}
    href={href}
    className={cx('whitespace-nowrap rounded-full flex-shrink-0', {
      'text-indigo-600 border-indigo-600': selected,
    })}
  >
    {name}
  </Button>
);

/**
 * A general purpose container for children that you want to side scroll,
 * fading to white on desktop and falling off the edge on mobile.
 */
export const ChicletsContainer = ({children, className}) => (
  <>
    <div className={cx('hidden md:block', className)}>
      <FadeOut className="-mx-4 -my-1" right left>
        <div className="flex overflow-x-scroll scrollbar-hidden px-4 py-1 space-x-2">
          {children}
        </div>
      </FadeOut>
    </div>
    <div className={cx('block md:hidden', className)}>
      <div className="overflow-hidden -mx-4 -my-1">
        <div className="flex overflow-x-scroll scrollbar-hidden px-4 py-1 space-x-2">
          {children}
        </div>
      </div>
    </div>
  </>
);

import React, {useState, useEffect} from 'react';
import cx from 'classnames';
import {RadioGroup} from '@headlessui/react';
import {useField, useFormikContext} from 'formik';
import {CloudUploadIcon} from '@heroicons/react/outline';

import DropdownInput from './DropdownInput';
import CollapserThing from './CollapserThing';
import DropDownMultiSelect from './DropDownMultiSelect';
import NonFormStarRating from './StarRating';

export const StyledTextInput = React.forwardRef(({className, ...props}, ref) => (
  <input
    className={cx('text-base rounded-lg shadow-sm border border-gray-300 focus:border-gray-400 px-3 py-2 w-full focus:outline-none', className)}
    {...props}
    ref={ref}
  />
));

export const StyledTextArea = React.forwardRef(({className, ...props}, ref) => (
  <textarea
    className={cx('text-base rounded-lg shadow-sm border border-gray-300 focus:border-gray-400 px-3 py-2 w-full focus:outline-none', className)}
    {...props}
    ref={ref}
  />
));

export const StyledSelect = React.forwardRef(({className, ...props}, ref) => (
  <select
    className={cx('text-base rounded-lg shadow-sm border border-gray-300 focus:border-gray-400 bg-white px-3 py-2 focus:outline-none appearance-none', className)}
    {...props}
    ref={ref}
  />
));

// TODO: Decouple this from Formik
export const StyledRadioButtons = React.forwardRef(({className, ...props}, ref) => (
  <div className="flex justify-items-start" ref={ref}>
    {props.options.map((option) => (
      <div className="mr-4">
        <label className="flex flex-col items-center select-none">
          <input
            type="radio"
            name={props.name}
            value={option.value}
            onClick={() => props.setValue(option.value)}
            className="opacity-0 h-0 w-0"
          />
          <div
            className={cx(
              'w-4 h-4 mb-2 inline-block shadow-sm rounded-full transition focus:ring-2',
              {'border border-gray-300': props.value !== option.value},
              {'ring-2 ring-indigo-300 bg-indigo-500 border-2 border-white': props.value === option.value},
            )}
          />
          <div>
            {option.label}
          </div>
        </label>
      </div>
    ))}
  </div>
));

// TODO: Decouple this from Formik
export const StyledRadioCardButtons = ({options, value, setValue}) => (
  <RadioGroup value={value} onChange={setValue} className="mt-2 grid grid-cols-3 gap-3 sm:grid-cols-6">
    {options.map((option) => (
      <RadioGroup.Option
        key={option.value}
        value={option.value}
        className={cx(
          'flex items-center justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold ring-1 hover:bg-gray-50 focus:ring-2 outline-none cursor-pointer sm:flex-1',
          {'text-gray-900 ring-gray-300 focus:ring-indigo': value !== option.value},
          {'text-indigo ring-indigo focus:ring-indigo': value === option.value},
        )}
      >
        {option.label}
      </RadioGroup.Option>
    ))}
  </RadioGroup>
);

export const StyledCheckbox = React.forwardRef(({className, ...props}, ref) => (
  <input
    type="checkbox"
    className={cx('form-checkbox focus:ring-indigo-500 my-1 h-4 w-4 text-indigo-600 border-gray-300 rounded', className)}
    checked={props.value}
    {...props}
    ref={ref}
  />
));

export const StyledFileInput = React.forwardRef(({className, ...props}, ref) => (
  <input
    type="file"
    className={cx('text-base rounded-lg shadow-sm border border-gray-300 focus:border-gray-400 px-3 py-2 focus:outline-none appearance-none', className)}
    {...props}
    ref={ref}
    value="" // Needed to disallow Formik from setting value for some reason https://stackoverflow.com/q/66876022/3681279
  />
));

export const LabelAndErrors = ({
  label, children, required, ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta] = useField(props);
  return (
    <div className="w-full flex flex-col mb-4">
      <label htmlFor={field.id || field.name} className="text-base text-gray-800">
        {label}
        {required && <span className="text-red-600" aria-label="Required"> *</span>}
      </label>
      {children}
      {meta.touched && meta.error ? (
        <div className="text-red-600 text-sm mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const TextInput = ({required, ...props}) => {
  const [field] = useField(props);
  return (
    <LabelAndErrors required={required} {...props}>
      <StyledTextInput {...field} {...props} />
    </LabelAndErrors>
  );
};

export const TextArea = ({required, ...props}) => {
  const [field] = useField(props);
  return (
    <LabelAndErrors required={required} {...props}>
      <StyledTextArea {...field} {...props} />
    </LabelAndErrors>
  );
};

export const Select = ({required, ...props}) => {
  const [field] = useField(props);
  return (
    <LabelAndErrors required={required} {...props}>
      <StyledSelect {...field} {...props} />
    </LabelAndErrors>
  );
};

export const RadioButtons = ({required, ...props}) => {
  const [field, meta, helpers] = useField(props);
  return (
    <LabelAndErrors required={required} {...props}>
      <StyledRadioButtons setValue={helpers.setValue} {...field} {...props} />
    </LabelAndErrors>
  );
};

export const RadioCardButtons = ({required, ...props}) => {
  const [field, meta, helpers] = useField(props);
  return (
    <LabelAndErrors required={required} {...props}>
      <StyledRadioCardButtons setValue={helpers.setValue} {...field} {...props} />
    </LabelAndErrors>
  );
};


export const Checkbox = ({label, ...props}) => {
  const [field] = useField(props);
  return (
    <div className="w-full flex mb-4">
      <label className="flex items-start font-medium text-gray-800">
        <StyledCheckbox {...field} {...props} />
        <span className="ml-2">{label}</span>
      </label>
    </div>
  );
};

export const StarRating = ({
  label, name, required, ...props
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <LabelAndErrors label={label} name={name} required={required}>
      <NonFormStarRating {...props} onRating={helpers.setValue} />
    </LabelAndErrors>
  );
};

export const FileInput = ({required, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const {value} = meta;
  const {setValue} = helpers;

  return (
    <LabelAndErrors required={required} {...props}>
      {value ? (
        <div className="flex">
          <span className="text-sm md:text-base font-medium leading-tight text-gray-600">
            {value.name}
          </span>
          <button
            type="button"
            onClick={() => setValue(undefined)}
            className="ml-4 text-sm md:text-base font-medium leading-tight text-indigo"
          >
            Remove
          </button>
        </div>
      ) : (
        <StyledFileInput {...field} {...props} />
      )}
    </LabelAndErrors>
  );
};

export const DropFileInput = ({required, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const {value} = meta;
  const {setValue} = helpers;
  const inputRef = React.useRef(null);

  const [fileError, setFileError] = React.useState(false);
  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // verify file is in correct format
      if (e.dataTransfer.files[0].type !== props.accept) {
        setFileError(true);
        return;
      }
      setFileError(false);
      setValue(e.dataTransfer.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <LabelAndErrors required={required} {...props}>
      {value ? (
        <div className="flex">
          <span className="text-sm md:text-base font-medium leading-tight text-gray-600">
            {value.name}
          </span>
          <button
            type="button"
            onClick={() => setValue(undefined)}
            className="ml-4 text-sm md:text-base font-medium leading-tight text-indigo"
          >
            Remove
          </button>
        </div>
      ) : (
        <div onDragEnter={handleDrag} className={cx(dragActive && 'bg-gray-200 border-gray-400', 'flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none')}>
          <StyledFileInput id="input-file-upload" {...field} {...props} className="hidden" ref={inputRef} />
          <label className="flex items-center justify-center space-x-2" htmlFor="input-file-upload">
            <CloudUploadIcon className="h-6 w-6 text-gray-600 mr-1" />
            {!dragActive ? (
              <span className="font-medium text-gray-600">
                {fileError && (<span className="font-semibold text-red-600">Incorrect file type. </span>)}
                Drop files to attach, or
                {' '}
                <button
                  type="button"
                  className="text-blue-600 underline"
                  onClick={onButtonClick}
                >
                  browse
                </button>
              </span>
            ) : (<span className="font-medium text-gray-600">Drop here</span>)}
          </label>
          { dragActive && (
          <div
            className="absolute w-full h-full rounded-lg top-0 right-0 bottom-0 left-0"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
          )}
        </div>
      )}
    </LabelAndErrors>
  );
};

// TODO: Make keyboard accessible
export const InputWithSuggestions = ({
  updateSuggestions,
  required,
  suggestions = [],
  open: initialOpen = false,
  ...props
}) => {
  const [open, setOpen] = useState(initialOpen);
  const [field] = useField(props);
  const {setFieldValue} = useFormikContext();

  const onChange = (e) => {
    setOpen(true);
    updateSuggestions(e.target.value);
    field.onChange(e);
  };

  const onSelectSuggestion = (value) => {
    setOpen(false);
    updateSuggestions(value);
    setFieldValue(field.name, value);
  };

  // Needed because: https://stackoverflow.com/a/57630197/3681279
  const preventDefault = (e) => e.preventDefault();

  return (
    <LabelAndErrors required={required} {...props}>
      <StyledTextInput
        {...field}
        {...props}
        onChange={onChange}
        onBlur={() => setOpen(false)}
        onFocus={() => setOpen(true)}
        autoFocus={initialOpen}
      />
      <CollapserThing visible={open && suggestions.length > 0}>
        <ul className="bg-white text-base rounded-lg shadow-md border border-gray-400 mt-2 h-48 overflow-scroll">
          {suggestions.map(({item}) => (
            <li key={item.id}>
              <button
                type="button"
                onMouseDown={preventDefault}
                onClick={() => onSelectSuggestion(item.name)}
                className="w-full px-3 py-2 hover:bg-gray-50 pointer text-left flex items-center"
              >
                {item.image_url && (
                  <img src={item.image_url} alt={`${item.name} logo`} className="h-4 w-4 mr-2 object-contain" />
                )}
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </CollapserThing>
    </LabelAndErrors>
  );
};

export const FormikDropdownInput = ({
  name, label, required, ...props
}) => {
  const [, {value}, {setValue}] = useField(name);
  return (
    <LabelAndErrors name={name} label={label} required={required}>
      <DropdownInput
        value={value}
        onChange={setValue}
        {...props}
      />
    </LabelAndErrors>
  );
};

export const FormikDropDownMultiSelect = ({
  name, label, options: initialOptions, placeHolder, required, ...props
}) => {
  const [, {value}, {setValue}] = useField(name);
  const [options, setOptions] = useState([]);

  const initializeOptions = (opts, selectedValues) => opts.map((option) => ({
    ...option,
    selected: selectedValues.includes(option.value),
  }));

  useEffect(() => {
    setOptions(initializeOptions(initialOptions, value));
  }, [initialOptions, value]);

  const onChange = (option) => {
    // Toggle the selected state of the option
    const updatedOptions = options.map((o) => (o.value === option.value
      ? {...o, selected: !o.selected} : o));

    // Update the options state
    setOptions(updatedOptions);

    // Update the value array based on the selected state
    const updatedValue = updatedOptions
      .filter((o) => o.selected)
      .map((o) => o.value);

    setValue(updatedValue);
  };

  return (
    <LabelAndErrors name={name} label={label} required={required}>
      <DropDownMultiSelect
        options={options}
        placeholderText={placeHolder || 'Select'}
        onChange={onChange}
        {...props}
      />
    </LabelAndErrors>
  );
};

export const NPS = ({
  name, label, options, required,
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <LabelAndErrors name={name} label={label} required={required}>
      <RadioGroup value={meta.value} onChange={helpers.setValue} className="mt-2 flex gap-3">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            className={cx(
              'flex items-center justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold ring-1 hover:bg-gray-50 focus:ring-2 outline-none cursor-pointer sm:flex-1',
              {'text-gray-900 ring-gray-300 focus:ring-indigo': meta.value !== option.value},
              {'text-indigo ring-indigo focus:ring-indigo': meta.value === option.value},
            )}
          >
            {option.label}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <div className="mt-3 flex justify-between text-sm font-medium leading-tight text-gray-600">
        <div>Not at all likely</div>
        <div>Extremely likely</div>
      </div>
    </LabelAndErrors>
  );
};

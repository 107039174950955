import * as React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export const Spinner = ({className}) => {
  const classes = cx('Spinner', className);
  return <div className={classes} />;
};

export const SpinnerPage = ({className}) => (
  <div className={`SpinnerPage ${className}`}>
    <Spinner />
  </div>
);

SpinnerPage.defaultProps = {
  className: '',
};

SpinnerPage.propTypes = {
  className: PropTypes.string,
};

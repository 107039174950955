import React, {useEffect} from 'react';
import {useRouter} from 'next/router';
import ReactModal from 'react-modal';
import ProgressBar from '@badrap/bar-of-progress';
import * as Sentry from '@sentry/react';

import {config} from '@fortawesome/fontawesome-svg-core'; // Prevent fontawesome from adding its CSS since we do it manually below
import {useAppContext} from '../js/hooks';
import {AppProvider} from '../js/components/AppContext';
import {AnalyticsProvider} from '../js/components/AnalyticsContext';
import PageContainer from '../js/components/PageContainer';
import DocumentHeader from '../js/components/DocumentHeader';
import {Website as WebsiteData} from '../js/components/LinkedData';
import {GoogleAnalyticsScript, ProfitWellScript, FomoScript} from '../js/components/Scripts';
import {
  addMouseListener,
  removeMouseListener,
  shouldShowOnboarding,
} from '../js/util';
import ErrorPage from '../js/pages/ErrorPage';
import {SENTRY_DSN} from '../js/constants/config';
import {getCanonicalURL} from '../js/util/seo';

import '@fortawesome/fontawesome-svg-core/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tailwindcss/tailwind.css';
import '../css/index.scss';

// The following line prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
config.autoAddCss = false;

const progress = new ProgressBar({
  size: 2,
  color: '#5524e0',
  className: 'bar-of-progress',
  delay: 100,
});

const APP_ROOT = '#__next';

const App = ({Component, pageProps}) => {
  const router = useRouter();
  const {currentUser, hasSubscription, loading} = useAppContext();

  useEffect(() => {
    Sentry.init({dsn: SENTRY_DSN, environment: process.env.NODE_ENV});

    ReactModal.setAppElement(APP_ROOT);

    // Add loading bar event handlers
    router.events.on('routeChangeStart', progress.start);
    router.events.on('routeChangeComplete', progress.finish);
    router.events.on('routeChangeError', progress.finish);

    addMouseListener();

    // Cleanup
    return () => {
      router.events.off('routeChangeStart', progress.start);
      router.events.off('routeChangeComplete', progress.finish);
      router.events.off('routeChangeError', progress.finish);
      removeMouseListener();
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      Sentry.setUser({
        id: currentUser?.id,
        email: currentUser?.email,
        ip_address: '{{auto}}',
      });

      if (shouldShowOnboarding(currentUser, router.pathname)) {
        const returnURL = router.query.r || encodeURIComponent(router.asPath);
        router.push(`/onboarding?r=${returnURL}`);
      }
    }
  }, [currentUser, loading]);

  const getLayout = Component.getLayout || ((page) => <PageContainer>{page}</PageContainer>);

  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <DocumentHeader
        imgPath="images/social_thumbnail.png"
        canonicalUrl={getCanonicalURL(router.asPath)}
      />
      <WebsiteData />

      {/* Third-party scripts */}
      {!loading && (
        <>
          <GoogleAnalyticsScript currentUser={currentUser} />
          <ProfitWellScript currentUser={currentUser} />
          <FomoScript hasSubscription={hasSubscription} />
        </>
      )}

      {/* Main app */}
      {getLayout(<Component {...pageProps} />)}
    </Sentry.ErrorBoundary>
  );
};

const AppWithContext = (props) => (
  <AppProvider>
    <AnalyticsProvider>
      <App {...props} />
    </AnalyticsProvider>
  </AppProvider>
);

export default AppWithContext;

import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import router from 'next/router';

import Button, {ButtonTypes, ButtonSizes} from './ButtonNew';
import {renewSubscription, getLatestActiveSubscription} from '../api/api';
import {useAnalytics, useAppContext} from '../hooks';
import FlashMessage from './FlashMessage';
import {extractAPIError} from '../util';

const ConfirmRenewalModal = ({
  open, onClose, renewed = false, errors = {status: false},
}) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(renewed);
  const [error, setError] = useState(errors);
  const {tracker} = useAnalytics();
  const {currentUser} = useAppContext();
  const [subscription, setSubscription] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const hideCallback = () => setError({status: false});

  const onSubmit = async () => {
    setLoading(true);
    tracker.bannerConfirmRenew();
    try {
      await renewSubscription({membership_type: subscription.data.interval});
      setLoading(false);
      setSubmitted(true);
    } catch (err) {
      setError({status: true, message: err.message});
    }
  };

  useEffect(() => {
    if (!submitted) {
      getLatestActiveSubscription(currentUser.id)
        .then((sub) => {
          const isActive = ['active', 'trialing', 'past_due'].includes(sub.status);
          if (isActive) {
            setSubscription((prev) => ({...prev, loading: false, data: sub}));
          } else {
            setSubscription((prev) => ({...prev, loading: false}));
          }
        }).catch((err) => {
          setSubscription((prev) => ({...prev, loading: false, error: extractAPIError(err)}));
        });
    }
  }, []);

  return (
    <Modal
      overlayClassName="fixed inset-0 bg-black bg-opacity-20 flex justify-center items-center z-40"
      className="bg-white w-full h-screen sm:h-auto sm:max-w-md p-8 sm:rounded-xl shadow-xl outline-none z-50"
      onRequestClose={onClose}
      isOpen={open}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      {!submitted && (
      <>
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          {`Renew now to ${subscription?.data?.interval === 'monthly'
            ? 'save $90'
            : 'save $50'}`}
        </h1>
        <p className="mt-3 text-base text-center text-gray-700">
          {subscription?.data?.interval === 'monthly'
            ? 'Take $30 off each month for 3 months!'
            : 'Get $50 off your annual plan!'}
          <br />
          {`Don't miss out on everything the Exponent membership has to offer.
          Renew today to keep access to all our courses, private community,
          and networking opportunities!`}
        </p>
        <div className="mt-6 flex flex-col justify-center">
          <Button
            onClick={onSubmit}
            loading={loading}
            buttonType={ButtonTypes.PRIMARY}
            size={ButtonSizes.LARGE}
            className="w-full"
          >
            Keep my membership
          </Button>
          <FlashMessage
            show={error.status}
            hideCallback={hideCallback}
            duration={3000}
          >
            <div className="absolute text-red-400">
              {error.message}
            </div>
          </FlashMessage>
        </div>
        <div className="mt-3 flex justify-center">
          <Button
            onClick={onClose}
            loading={loading}
            buttonType={ButtonTypes.SECONDARY}
            className="border-none shadow-none"
          >
            No thanks
          </Button>
        </div>
      </>
      )}
      {submitted && (
      <>
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Success!
        </h1>
        <p className="mt-3 text-base text-center text-gray-700">
          {`You've successfully renewed your membership. 
        We're excited to continue having you in our community and helping you land your dream career.`}
        </p>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          size={ButtonSizes.LARGE}
          onClick={() => router.reload()}
          className="mt-6 w-full"
        >
          Keep Learning
        </Button>
      </>
      )}
    </Modal>
  );
};

export default ConfirmRenewalModal;

/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/no-autofocus */

import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import {signUpForEmail} from '../api/api';

import Link from './Link';
import {useExitIntent, useAnalytics, useAppContext} from '../hooks';

const STORAGE_KEY = 'special_offer_shown';
const INITIAL_DELAY_MS = 5 * 1000; // 5 seconds
const DELAY_MS = 24 * 60 * 60 * 1000; // 1 day
const PROMO_CODE = 'NEWYEAR22';

const SpecialOfferModal = ({metadata, exitEnabled, scrollEnabled}) => {
  const [open, setOpen] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const {features} = useAppContext();
  const {tracker} = useAnalytics();
  const showUpgrade = features.special_offer && features.special_offer === 'on_with_offer';
  const {tag} = metadata;

  const roleInfo = {
    pm: {
      header: 'Get product management advice in your inbox.',
      description: "Join 75,000+ PMs on Exponent's free newsletter.",
      dripTag: 'career_pm',
    },
    pmm: {
      header: 'Get PMM advice in your inbox.',
      description: "Join 15,000+ product marketers on Exponent's free newsletter.",
      dripTag: 'career_pmm',
    },
    swe: {
      header: 'Get software engineering advice in your inbox.',
      description: "Join 68,000+ engineers on Exponent's free newsletter.",
      dripTag: 'career_swe',
    },
    'system-design': {
      header: 'Get system design advice in your inbox.',
      description: "Join 150,000+ engineers and PMs on Exponent's free newsletter.",
      dripTag: 'career_swe',
    },
    sql: {
      header: 'Get SQL tips and tricks in your inbox.',
      description: "Join 150,000+ engineers and analysts on Exponent's free newsletter.",
      dripTag: 'career_data',
    },
    em: {
      header: 'Get engineering management advice in your inbox.',
      description: "Join 150,000+ engineers and managers on Exponent's free newsletter.",
      dripTag: 'career_em',
    },
    tpm: {
      header: 'Get TPM advice in your inbox.',
      description: "Join 25,000+ program managers on Exponent's free newsletter.",
      dripTag: 'career_tpm',
    },
    'product-designer': {
      header: 'Get design and UX advice in your inbox.',
      description: "Join 8,000+ designers on Exponent's free newsletter.",
      dripTag: 'career_design',
    },
    default: {
      header: 'Get our free tech newsletter',
      description: 'Sign up to get the latest news, updates, and offers delivered directly to your inbox.',
      dripTag: null,
    },
  };

  const role = tag && roleInfo[tag.id] ? tag.id : 'default';
  const newsletterCopy = roleInfo[role];

  const onSubmit = async () => {
    setLoading(true);
    tracker.exitModalSubmit({features});
    if (showUpgrade) {
      await signUpForEmail(email, null, 'special_offer_modal');
    } else {
      await signUpForEmail(email, roleInfo[role].dripTag);
    }
    setSubmitted(true);
    setLoading(false);
  };

  const onExit = () => {
    const lastShown = localStorage.getItem(STORAGE_KEY);
    const shouldShow = !lastShown || parseInt(lastShown, 10) + DELAY_MS < Date.now();

    if (!modalShown && shouldShow) {
      setOpen(true);
      localStorage.setItem(STORAGE_KEY, Date.now());
      tracker.exitModalView({features});
    }
  };

  // Scroll modal
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition = 2000; // Adjust this value to control when the modal should appear

      if (!modalShown && scrollPosition > triggerPosition) {
        onExit();
      }
    };

    if (scrollEnabled) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollEnabled) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [modalShown, scrollEnabled]);

  // Exit modal
  useExitIntent(onExit, {}, exitEnabled);

  return (
    <Modal
      overlayClassName="fixed inset-0 bg-black bg-opacity-20 flex justify-center items-end sm:items-center z-10"
      className="flex bg-white w-full sm:w-4/5 md:w-1/2 px-10 m-2 max-w-3xl rounded-xl shadow-xl outline-none z-20"
      onRequestClose={() => setOpen(false)}
      isOpen={open}
    >
      {!submitted && (
        <div className="lg:px-10 pt-10 pb-8">
          <div className="text-center">
            {showUpgrade ? (
              <>
                <span className="inline-block mb-1 text-sm font-semibold tracking-wider text-indigo uppercase">
                  Special offer
                </span>
                <h2 className="text-3xl font-extrabold text-black tracking-tight sm:text-4xl">
                  Enjoy 10% off all Exponent courses and coaching
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-gray-600">
                  For a limited time, sign up and receive a 10% off coupon—use it for
                  any course or membership plan for the next 24 hours.
                </p>
              </>
            ) : (
              <>
                <span className="text-2xl lg:text-3xl font-extrabold text-black tracking-tight leading-tight sm:text-4xl">
                  {newsletterCopy.header}
                </span>
                <p className="mt-2 mx-auto max-w-2xl text-base lg:text-lg leading-tight text-gray-600">
                  {newsletterCopy.description}
                </p>
              </>
            )}
          </div>
          <form action="#" className="mt-8 sm:mx-auto sm:max-w-lg flex flex-col lg:flex-row space-y-2 lg:space-x-2 lg:space-y-0">
            <div className="min-w-0 flex-1">
              <label htmlFor="cta-email" className="sr-only">
                Email address
              </label>
              <input
                id="cta-email"
                type="email"
                className="block w-full border-2 border-gray-300 rounded-md px-5 py-3 text-base text-gray-800 placeholder-gray-500 shadow-sm focus:outline-none focus:border-indigo-600"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
                autoFocus
                required
              />
            </div>
            <div className="mt-4 sm:mt-0">
              <button
                type="submit"
                className="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo text-base font-medium text-white shadow hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                onClick={onSubmit}
                disabled={loading}
              >
                {showUpgrade ? 'Get offer' : 'Submit'}
              </button>
            </div>
          </form>
          <div className="mt-6 flex justify-center">
            <button
              className="text-gray-500 font-medium"
              type="button"
              onClick={() => setOpen(false)}
            >
              No thanks
            </button>
          </div>
        </div>
      )}
      {submitted
        && (
          <div className="w-full flex-col justify-center p-10 pt-12">
            {showUpgrade ? (
              <>
                <div className="text-center">
                  <p className="mb-4 text-lg sm:text-xl font-extrabold text-gray-800">
                    Thanks!
                    <br />
                    Use this code at checkout to save 10%
                  </p>
                  <h2 className="text-2xl sm:text-2xl p-3 bg-gray-100 rounded-xl font-extrabold text-gray-900">
                    {PROMO_CODE}
                  </h2>
                </div>
                <div className="mt-6 flex justify-center">
                  <Link
                    className="text-gray-500 font-medium"
                    href="/upgrade?src=special_offer_modal"
                    onClick={() => setOpen(false)}
                  >
                    Take me to checkout
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="text-center">
                  <h2 className="mb-4 text-lg sm:text-xl font-extrabold text-indigo">
                    Thanks for subscribing!
                  </h2>
                  <p className="text-2xl sm:text-2xl p-3 font-bold text-gray-900">
                    We&apos;re excited to help you land your dream job.
                  </p>
                  <div className="mt-6 flex justify-center">
                    <button
                      className="text-gray-500 font-medium"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Click to close
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
    </Modal>
  );
};

export default SpecialOfferModal;
